import React, { useEffect, useMemo, useState } from 'react'

import { date, dbdate, debounce, dError, get, num, post, qsget, qsset, todate, tSuccess, useUser } from 'unno-comutils'
import { Button, ButtonSave, createPaging, Icon, Modal, PageTitle, Paging, Wait } from 'unno-comutils/ui'
import { diffDate } from 'unno-comutils/utils'
import { Checkbox, fromRangeParam, Input, InputDate, InputDateRange, InputSearch, InputSearchRow, InputTime, Radio, Select } from 'unno-comutils/form'
import Datagrid, { IDatagridColumns } from 'unno-comutils/Datagrid'

import { COMPANY_BANKs, OVERDUE_FINEs, PAYMENT_CHANNELs } from '../../service/var.meta'
import { ButtonCell, ButtonPay } from '../../components/cell'
import BookView from '../book/view'
import { hhash, hhashRemove, hhashUpdate } from '../../utils'
import { createTags } from '../../service/search'

//const TAX50_RATE = 0.015
const TAX50_RATE = 0.03

export const DEFAULT_PAY_SUCCESS = { id: null, just: true }

const calFine = (d: any, dateDue: any) => {
    const diff = diffDate(dateDue, d, 'd')

    if (diff <= 0) return 0

    let output = 0
    for (const x in OVERDUE_FINEs) {
        const od = OVERDUE_FINEs[x]
        if (diff >= od.day)
            output = od.money
    }

    return output
}

export default function Customer () {
    const [wait, setWait] = useState(false)
    const [lists, setLists] = useState([])

    const [tags, setTags] = useState<any>([])
    const [search, setSearch] = useState('')
    const [searchDate, setSearchDate] = useState<any>('')
    const [searchAgent, setSearchAgent] = useState<any>('')
    const [searchEmployee, setSearchEmployee] = useState<any>('')

    const [pay, setPay] = useState<any>(null)
    const [paySuccess, setPaySuccess] = useState<any>(DEFAULT_PAY_SUCCESS)
    const [viewDocument, setViewDocument] = useState<any>(null)

    const [paging, setPaging] = useState(createPaging(1))

    // ----- ACTION

    const loadList = debounce((page?: any) => {
        if (!page) page = paging
        const qs = { page: page?.page || 1, search, date: dbdate(searchDate), agent: searchAgent?.id, employee: searchEmployee?.id }
        setWait(true)
        get('account/customer/list', qs).then(({ ok, datas, paging }) => {
            if (ok) {
                const h = hhash()
                if (!h) qsset(qs)

                setTags(createTags({ searchDate, setSearchDate, searchEmployee, setSearchEmployee, searchAgent, setSearchAgent }))

                setLists(datas)
                setPaging(paging)
            }
        }).finally(() => setWait(false))
    }, 500)

    const dhash = debounce(() => {
        const h = hhash()
        if (h && h.type === 'view') setViewDocument(h.id)
        if (h && h.type === 'customer_pay') setPay(h.id)
    }, 500)

    // ----- EVENT

    const saveData = (pay: any) => {
        setPay(null)
        setPaySuccess(pay)
        loadList()
    }

    // ----- MEMO

    useEffect(() => {
        const qs = qsget()
        const { agent, employee } = qs
        if (qs.search) setSearch(qs.search)
        if (qs.date) setSearchDate(fromRangeParam(qs.date))
        if (qs.page) setPaging(createPaging(num(qs.page)))

        if (agent || employee) {
            get('app/qs', { agent, employee }).then(d => {
                if (d.ok) {
                    if (d.agent) setSearchAgent(d.agent)
                    if (d.employee) setSearchEmployee(d.employee)
                }
            })
        }

        dhash()

    }, [])

    useEffect(() => {
        loadList()
    }, [search, searchDate, searchAgent, searchEmployee])

    const reportUrl = useMemo(() => {
        return qsset({ search, date: dbdate(searchDate), agent: searchAgent?.id, employee: searchEmployee?.id }, true)
    }, [search, searchDate, searchAgent, searchEmployee])

    const tableDatas = useMemo(() => {
        return lists.map((d: any) => {
            const lot = (d.paylotCount <= 1 ? 'เงินสด' : (d.lot + (d.lotSplit ? `/${d.lotSplit}` : '')))
                + (d.lot === d.paylotCount ? ' (งวดสุดท้าย)' : '')
                + (!!d.documentNote ? ' ' + d.documentNote : '')

            d.dateDue = date(d.dateDue, 'S')
            d.lot = lot

            d.license = d.vehicleLicense || '?'
            d.company = d.companyName || '?'

            d.agent = d.agentName
            d.employee = d.employeeName

            d.insureType = d.metaInsuretypeName
            d.paylotMoneyWait = num(d.paylotMoney - d.paylotMoneyPay)
            return d
        })
    }, [lists])

    const tableColumns = useMemo<IDatagridColumns[]>(() => [
        { name: '_rownum', label: '#', width: 50, style: 'c', pinned: 'left' },
        {
            name: 'btn_form', label: '', width: 100,
            renderer: (_: any, d: any) => <ButtonPay value={d} onClick={(id: any) => setPay(id)} onRePrint={(id: any) => setPaySuccess({ id })}/>
        },
        { name: 'dateDue', label: 'วันที่นัดชำระ', width: 110, style: 'c' },
        { name: 'lot', label: 'งวดที่', width: 150 },

        { name: 'money', label: 'ยอดชำระ', width: 80, style: 'r', format: 'number' },
        { name: 'moneySplit', label: 'ยอดแบ่ง', width: 80, style: 'r', format: 'number' },
        { name: 'moneyPay', label: 'ยอดจ่าย', width: 80, style: 'r -blue', format: 'number' },

        { name: 'customer', label: 'ชื่อลูกค้า (เบอร์โทร)', width: 270 },
        { name: 'customerPay', label: 'ผู้ผ่อน (เบอร์โทร)', width: 270 },
        { name: 'license', label: 'ทะเบียน', width: 100, style: 'c' },
        { name: 'company', label: 'บริษัท', width: 250 },
        { name: 'insureType', label: 'ประเภทประกัน', width: 100, style: 'c' },
        { name: 'paylotMoneyPay', label: 'ยอดชำระค่างวด', width: 110, style: 'r', format: 'number' },
        { name: 'paylotMoneyWait', label: 'ยอดค้างชำระ', width: 100, style: 'r', format: 'number' },
        { name: 'agent', label: 'ตัวแทน', width: 130 },
        { name: 'employee', label: 'พนักงาน', width: 100 },
        {
            name: 'btn_history', label: '', width: 80,
            renderer: (_: any, d: any) => <ButtonCell value={d} text="ประวัติ" state={'dark'} onClick={() => setViewDocument(d.documentid)}/>
        },
    ], [])

    // ----- RENDER

    return <>
        <PageTitle icon="sack-dollar" title="รายรับ">
            <InputSearch tags={tags} value={search} onChange={setSearch} onRefresh={() => loadList()}>
                <InputSearchRow label="วันที่นัดชำระ" children={<InputDateRange icon fixWidth value={searchDate} onChange={v => setSearchDate(v)}/>}/>
                <InputSearchRow label="ตัวแทน" children={<Select value={searchAgent} url="/ac/agent" onChange={(_, v) => setSearchAgent(v)}/>}/>
                <InputSearchRow label="พนักงาน" children={<Select value={searchEmployee} url="/ac/employee" onChange={(_, v) => setSearchEmployee(v)}/>}/>
            </InputSearch>

            {searchDate && <>
                {/*<Button icon={'print'} href={'/d/customer_document.pdf?' + reportUrl} secondary targetBlank className="ml-4">PDF</Button>*/}
                <Button icon={'table'} href={'/d/customer_document.xlsx?' + reportUrl} secondary targetBlank className="ml-2">Excel</Button>
                <Button icon={'file-invoice'} href={'/d/customer_document_bill.xlsx?' + reportUrl} secondary targetBlank className="ml-2">ใบวางบิล</Button>
            </>}


        </PageTitle>

        <Datagrid wait={wait} wrapText name={'account_customer'} datas={tableDatas} columns={tableColumns}/>
        <Paging paging={paging} onChange={loadList}/>

        <BookView id={viewDocument} onClose={() => setViewDocument(null)}/>
        <CustomerFormPay id={pay} onSave={saveData} onClose={() => setPay(null)}/>
        <CustomerPaySuccess {...paySuccess} onClose={() => setPaySuccess(DEFAULT_PAY_SUCCESS)}/>
    </>
}

export function CustomerFormPay (props: any) {
    const user = useUser()

    const [pay, setPay] = useState<any>({})
    const [document, setDocument] = useState<any>({})

    const [form, setForm] = useState<any>({})

    const [waitComAgent, setWaitComAgent] = useState(false)

    // ----- ACTION

    const loadData = () => {
        if (props.id > 0) {
            const url = props.isOther ? 'book/get/' + props.id : 'book_paylot/get/' + props.id
            get(url).then(({ ok, data }) => {
                if (ok) {
                    let doc = props.isOther ? data : data.document
                    let _pay: any = {}

                    if (props.isOther) {
                        _pay = {
                            id: data.id, money: 0, moneyService: 0, moneyFine: 0, moneyOver: 0, moneyDiscount: 0,
                            fineNote: 0, paymentChannel: 0, bankData: 0, note: 0, document: doc
                        }
                    }
                    else {
                        _pay = data
                        _pay.moneyBalance = _pay.document ? (_pay.document.paylotMoney - _pay.document.paylotMoneyPay) : 0
                    }

                    const { document, datePay, dateDue, money, moneyService, moneyFine, moneyOver, fineNote, paymentChannel, bankData, note } = _pay
                    const agentMoney = document?.agentMoney || 0
                    const agentMoneyTax = document?.agentMoneyTax || 0
                    const agentMoney_per = Math.round(agentMoney / document?.moneyInsure * 100)
                    let _datePay = todate(datePay && datePay !== '0000-00-00 00:00:00' ? datePay : undefined)

                    setForm({
                        id: props.id,
                        payMode: props.isOther ? 3 : 0,
                        isComm: 0,
                        datePay: _datePay,
                        datePay_time: date(_datePay, 't'),

                        money: props.isOther ? 0 : money,
                        moneyService, moneyOver, moneyDiscount: 0,
                        moneyFine: moneyFine || calFine(_datePay, dateDue),

                        agentMoney_per, agentMoney, agentMoneyTax,
                        fineNote,
                        paymentChannel,
                        bankData: bankData?.number || '',
                        note,
                    })
                    setPay(_pay)
                    setDocument(document)
                    hhashUpdate('customer_pay,' + data.id)

                    if (props.slip) {
                        get('customer_slip/get/' + props.slip).then(({ ok, data }) => {
                            if (ok) {
                                const update: any = { datePay: data.slipTime, datePay_time: date(data.slipTime, 't') }
                                console.log(data.result?.receiver?.account?.value)
                                if (data.result?.receiver?.account?.value) {
                                    const bank = COMPANY_BANKs.find((b: any) =>
                                        b.id.replace(/\D/g, '') === data.result?.receiver?.account?.value ||
                                        b.id.replace(/\D/g, '').includes(data.result?.receiver?.account?.value.replace(/\D/g, '')))
                                    if (bank) {
                                        update.paymentChannel = bank.payways[0]
                                        update.bankData = bank.id
                                    }
                                }
                                onChange(update)
                            }

                        })
                    }
                }
                else props.onClose()
            })
        }
        else {
            setForm({})
        }
    }

    const saveData = (c: any) => {
        const data = {
            ...form,
            slipId: props.slip || 0,
            datePay: dbdate(form.datePay),
            isComm: form.isComm ? 1 : 0,
            isOther: props.isOther ? 1 : 0
        }

        post(props.isOther ? 'book_paylot/other/' + props.id : 'book_paylot/pay/' + props.id, data).then(d => {
            if (d.ok) {
                const line = document.customerPay?.line || document.customer?.line || 0
                const payEnd = d.document && d.document.paylotMoney <= d.document.paylotMoneyPay

                props.onSave({ id: d.id, just: true, agent: data.isComm === 1, line, payEnd })
                props.onClose()

            }
            else dError(d)
        }).finally(c)
    }

    const sendLine = (c: any) => {
        get('book_paylot/send_line/' + document.id).then(d => {
            if (d.ok) {
                tSuccess(`ส่งทั้งหมด ${d.count} รายการ`)
            }
        }).finally(c)
    }

    // ----- EVENT

    const onCommAgent = (check: any) => {
        if (check && document.agent) {
            setWaitComAgent(true)
            get('book/cal_agent_re/' + document.id).then(d => {
                if (d.ok) {
                    const { agentMoney, agentMoneyTax } = d
                    const agentMoney_per = Math.round(agentMoney / document?.moneyInsure * 100)

                    setWaitComAgent(false)
                    onChange({ isComm: check, agentMoney_per, agentMoney, agentMoneyTax })
                }
            })
        }
        else { onChange({ isComm: check }) }
    }

    const onChange = (update: any) => setForm((prev: any) => ({ ...prev, ...update }))

    const payMode = (v: any) => {
        const update: any = { payMode: v, money: v === 2 ? pay.moneyBalance : pay.money }
        if (v !== 2) update.moneyDiscount = 0
        onChange(update)
    }

    const onClose = () => {
        hhashRemove()
        setForm({})
    }

    // ----- MEMO

    let moneyNet = num(form?.money)
    moneyNet += num(form?.moneyService)
    moneyNet += num(form?.moneyFine)
    moneyNet += num(form?.moneyOver)
    moneyNet -= num(form?.moneyDiscount)
    if (form.isComm) {
        moneyNet -= num(form?.agentMoney)
        moneyNet += num(form?.agentMoneyTax)
    }

    const COMPANY_BANK_OPTIONs = useMemo(() => {
        return COMPANY_BANKs?.filter((b: any) => b.payways && b.payways.indexOf(form.paymentChannel) >= 0) || []
    }, [])

    const vehicles = useMemo(() => {
        const vehicles = []
        if (document.vehicleLicense) vehicles.push(document.vehicleLicense)
        if (document.metaVehicletype) vehicles.push(document.metaVehicletype.name)
        if (document.metaVehicle) vehicles.push(document.metaVehicle.name)
        return vehicles
    }, [document])

    // ----- RENDER

    return <Modal title="ข้อมูลบันทึกจ่าย" className="customer-Form" md noCloseBackdrop open={props.id !== null} onClose={props.onClose}
                  onOpenEnd={loadData} onCloseEnd={onClose}
                  footer={<ButtonSave state={'success'} onSave={sendLine} className="ml-0">ส่งไลน์ QRCode</ButtonSave>} footerSave={saveData}>
        <div className={'pay-header'}>
            <div className={'_customer'}>
                <div className={'_name'}>
                    {document.customer?.name || '?'}
                    {!!document.customer?.telephone && <span className={'_tel'}><Icon name={'phone'}/>{document.customer?.telephone}</span>}
                </div>
                <div className={'_detail'}>{vehicles.join(' , ')}</div>
                <div className={'_detail'}>{`${document.company?.name} ${document.metaInsuretype?.name || ''}`}</div>
            </div>
            {!props.isOther && <div className={'_lot'}>
                <div className={'_label'}>งวดที่</div>
                <div className={'_value'}>{num(pay.lot) + (pay.lotSplit ? `/${pay.lotSplit}` : '')}</div>
            </div>}
        </div>

        <div className="row">
            <InputDate label={'วันที่จ่าย'} classBox={'col w-3/12'} noClear
                       value={form.datePay || ''} onChange={v => onChange({ datePay: v, moneyFine: calFine(v, pay.dateDue) })}/>
            <InputTime label={'เวลา'} classBox={'col w-2/12'} value={form.datePay_time || ''} onChange={v => onChange({ datePay_time: v })}/>
            {(!props.isOther) &&
                <div className="col ml-auto">
                    <Radio value={form.payMode} options={[{ id: 0, name: 'เต็มจำนวน' }, { id: 1, name: 'แบ่งชำระ' }, { id: 2, name: 'ปิดงวด' }]} onChange={v => payMode(v)}/>
                </div>}
        </div>

        <div className="row">
            {!props.isOther ?
                <Input type="number" label={'ค่าเบี้ยประกัน'} classBox="col w-3/12" right unit="บาท" readOnly={form.payMode !== 1}
                       value={form.money || ''} onChange={v => onChange({ money: v })}/>
                : <div className={'col w-3/12'}/>}
            <Input value={form.moneyService || ''} type="number" label={'ค่าบริการ'} classBox="col w-3/12" right unit="บาท" onChange={v => onChange({ moneyService: v })}/>
            {(!props.isOther && form.payMode === 2) &&
                <Input value={form.moneyDiscount} type="number" label={'ส่วนลดดอกเบี้ย'} right unit="บาท" classBox="col w-3/12" onChange={v => onChange({ moneyDiscount: v })}/>}
        </div>

        {document.agent?.id > 0 && ((!document.payagent || document.payagent.id === 0) && !props.isOther)
            && <div className="row">
                <Checkbox text={'หักค่าคอม'} classBox="col w-2/12 mb-7" checked={form.isComm} onChange={onCommAgent}/>
                {!!form.isComm ?
                    <>
                        <Input label={'%'} type="number" classBox="col w-1/12" center value={form.agentMoney_per} readOnly={!user.admin}
                               onChange={v => onChange({
                                   agentMoney: (document.moneyInsure * (v / 100)).toFixed(2),
                                   agentMoneyTax: ((document.moneyInsure * (v / 100)) * TAX50_RATE).toFixed(2),
                                   agentMoney_per: v
                               })}/>
                        <Input type="number" label={'ค่าคอม'} classBox="col w-3/12" right unit="บาท" readOnly={!user.admin}
                               value={form.agentMoney}
                               onChange={v => onChange({ agentMoney: v, agentMoneyTax: (v * TAX50_RATE).toFixed(2), agentMoney_per: Math.round(v / document.moneyInsure * 100) })}/>
                        <Input type="number" label={'หักภาษี ณ ที่จ่าย'} classBox="col w-3/12" right unit="บาท"
                               value={form.agentMoneyTax} readOnly={!user.admin} onChange={v => onChange({ agentMoneyTax: v })}/>
                    </> : (waitComAgent && <div className="mt-2"><Icon name={'spinner red'} spin/></div>)}
            </div>}

        <div className="row">
            <Input value={form.moneyFine || ''} type="number" label={'ค่าปรับ'} classBox={'col w-3/12 offset-3'} right unit="บาท" onChange={v => onChange({ moneyFine: v })}/>
            {form.moneyFine > 0 && <Input value={form.fineNote} label={'งวดค่าปรับ'} classBox="col w-2/12" center onChange={v => onChange({ fineNote: v })}/>}

            <Input value={form.moneyOver || ''} type="number" label={'เงินเกิน'} classBox="col w-3/12 ml-auto" right unit="บาท" onChange={v => onChange({ moneyOver: v })}/>
            <Input readOnly value={num(Math.ceil(moneyNet), 2)} label={'ยอดชำระ'} classBox={'col w-3/12 offset-4'} right unit="บาท"/>
        </div>

        <Radio className="mb-4" options={PAYMENT_CHANNELs} value={form.paymentChannel}
               onChange={v => onChange({ paymentChannel: v, bankData: COMPANY_BANKs.find((b: any) => b.payways && b.payways.indexOf(v) >= 0) })}/>

        {!!COMPANY_BANK_OPTIONs && COMPANY_BANK_OPTIONs.length > 0 &&
            <Select label="ธนาคาร" value={form.bankData} options={COMPANY_BANK_OPTIONs} onChange={v => onChange({ bankData: v })}/>}
        <Input m0 label="หมายเหตุ" multiline value={form.payNote} onChange={v => onChange({ payNote: v })}/>
    </Modal>
}

export function CustomerPaySuccess (props: any) {
    const user = useUser()

    const [data, setData] = useState<any>(null)

    const [wait, setWait] = useState(false)
    const [lineWait, setLineWait] = useState(false)
    const [urbanLineWait, setUrbanLineWait] = useState(false)

    const loadData = () => {
        setWait(true)
        get('book_paylot/get/' + props.id).then(d => {
            if (d.ok) {
                setData({ ...d.data, canNotify: d.canNotify })
            }
        }).finally(() => setWait(false))
    }

    const sendLine = () => {
        setLineWait(true)
        get('book/send_line/' + props.id).then(d => {
            if (d.ok) tSuccess('ส่ง Line เรียบร้อยแล้ว')
            else dError(d)
        }).finally(() => setLineWait(false))
    }

    const sendUrbanLine = () => {
        setUrbanLineWait(true)
        get('book/send_urban_line/' + props.id).then(d => {
            if (d.ok) tSuccess('ส่ง Line เรียบร้อยแล้ว')
            else dError(d)
        }).finally(() => setUrbanLineWait(false))
    }

    return <Modal title={props.just ? 'บันทึกข้อมูลสำเร็จ' : 'พิมพ์ใบเสร็จย้อนหลัง'} sm open={props.id !== null} onOpenEnd={loadData} onClose={props.onClose}>
        {wait && <Wait/>}
        <Icon name={props.just ? 'check-circle green' : 'print blue'} className={'dialog-icon'}/>
        {data && <div className={'dialog-link'}>
            <div className={'_row'}>
                <Button m0 secondary outline href={'/d/document_receipt.pdf?id=' + props.id} targetBlank>พิมพ์ใบเสร็จ</Button>
                <Button m0 secondary outline href={'/d/document_receipt.pdf?id=' + props.id + '&img=1'} targetBlank>รูป</Button>
                {data.canNotify ? <Button m0 success onClick={sendLine} loading={lineWait}>Line</Button> : null}
                {user.admin && <Icon button name={'star orange ml-2'} href={'/d/document_receipt.pdf?id=' + props.id} targetBlank/>}
            </div>
            {!!data.isPayangent && <div className={'_row'}>
                <Button m0 secondary outline href={'/d/document_receipt.pdf?id=' + props.id + '&agent=1'} targetBlank>พิมพ์ใบเสร็จ+หักค่านายหน้า</Button>
                <Button m0 secondary outline href={'/d/document_receipt.pdf?id=' + props.id + '&agent=1&img=1'} targetBlank>รูปใบเสร็จ</Button>
            </div>}
            {data.document && data.document.paylotMoney <= data.document.paylotMoneyPay && data.canNotify && <div className={'_row no-boxmargin'}>
                <Button m0 color={'sky'} onClick={sendUrbanLine} loading={urbanLineWait}>ส่งไลน์แก้ไขที่อยู่ให้ลูกค้า</Button>
            </div>}
        </div>}
    </Modal>
}
