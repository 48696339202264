import React, { useEffect, useState } from 'react'

import { changeDate } from 'unno-comutils'
import { FormContainer, Input, InputDate, Radio, Select } from 'unno-comutils/form'

import { VEHICLE_FIXPLACEs } from '../../../service/var'
import { age } from '../../../utils'
import { COMPANYs, META_INSURETYPEs } from '../../../service/var.meta'

export function FormOlddoc (props: { oldData: any, onChange: (d: any) => void }) {
    const [data, setData] = useState<any>({})

    const onChange = (update: any) => {
        const oldData = { ...data, ...update }
        setData(oldData)
        props.onChange({ oldData })
    }

    useEffect(() => {
        setData(props.oldData || {})
    }, [props.oldData])

    if (!data) return <></>

    return <FormContainer collapse={'book_insurance_old'} label={'ข้อมูลประกันภัยเดิม'} className={'docform-insurance-old'}>
        <div className="row">
            <Select label="บริษัทประกันเดิม" classBox="col w-2/6" options={COMPANYs} noClear value={data.company} onChange={(_, company) => onChange({ company })}/>
            <Select label="ประเภทประกันเดิม" classBox="col w-2/6" options={META_INSURETYPEs} value={data.metaInsuretype} onChange={(_, v) => onChange({ metaInsuretype: v })}/>
            <InputDate icon label={'วันเริ่มคุ้มครอง'} classBox="col w-1/6" noClear value={data.dateStart || ''}
                       onChange={v => onChange({ dateStart: v, dateEnd: changeDate(v, 1, 'y') })}/>
            <InputDate icon label={'วันสิ้นสุดคุ้มครอง'} classBox="col w-1/6" noClear value={data.dateEnd || ''} onChange={v => onChange({ dateEnd: v })}/>
        </div>
        <div className="row">
            <InputDate icon label={'วันเกิดคนขับ 1'} classBox="col w-1/6" value={data.driver1 || ''} onChange={v => onChange({ driver1: v })}/>
            {data.driver1 && <div className="col w-1/6 pt-2 mt-px red">อายุ {age(data.driver1) || '?'} ปี</div>}
            <InputDate icon label={'วันเกิดคนขับ 2'} classBox="col w-1/6" value={data.driver2 || ''} onChange={v => onChange({ driver2: v })}/>
            {data.driver2 && <div className="col w-1/6 pt-2 mt-px red">อายุ {age(data.driver2) || '?'} ปี</div>}
        </div>
        <div className="row">
            <Radio value={data.vehicleFixplace} classBox="col flex-1" options={VEHICLE_FIXPLACEs} onChange={vehicleFixplace => onChange({ vehicleFixplace })}/>
            <Input label="ทุนประกันภัยเดิม" type="number" right classBox="col w-2/12" unit="บาท" value={data.moneyFund} onChange={v => onChange({ moneyFund: v })}/>
            <Input label="เบี้ยประกันภัยรวมเดิม" type="number" right classBox="col w-2/12" unit="บาท" value={data.moneyTotalFund} onChange={v => onChange({ moneyTotalFund: v })}/>
            <Input label="ดีดักเดิม" type="number" right classBox="col w-2/12" unit="บาท" value={data.moneyDeduct} onChange={v => onChange({ moneyDeduct: v })}/>
        </div>
    </FormContainer>
}
