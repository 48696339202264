export const ADDRESS_PROVINCEs = [
    { id: 0, name: '-', short: '', part: '-' }, 
    { id: 10, name: 'กรุงเทพมหานคร', short: 'กท', part: 'กลาง' }, 
    { id: 81, name: 'กระบี่', short: 'กบ', part: 'ใต้' }, 
    { id: 71, name: 'กาญจนบุรี', short: 'กจ', part: 'ตะวันตก' }, 
    { id: 46, name: 'กาฬสินธุ์', short: 'กส', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 62, name: 'กำแพงเพชร', short: 'กพ', part: 'กลาง' }, 
    { id: 40, name: 'ขอนแก่น', short: 'ขก', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 22, name: 'จันทบุรี', short: 'จบ', part: 'ตะวันออก' }, 
    { id: 24, name: 'ฉะเชิงเทรา', short: 'ฉช', part: 'ตะวันออก' }, 
    { id: 20, name: 'ชลบุรี', short: 'ชบ', part: 'ตะวันออก' }, 
    { id: 18, name: 'ชัยนาท', short: 'ชน', part: 'กลาง' }, 
    { id: 36, name: 'ชัยภูมิ', short: 'ชย', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 86, name: 'ชุมพร', short: 'ชพ', part: 'ใต้' }, 
    { id: 50, name: 'เชียงใหม่', short: 'ชม', part: 'เหนือ' }, 
    { id: 57, name: 'เชียงราย', short: 'ชร', part: 'เหนือ' }, 
    { id: 92, name: 'ตรัง', short: 'ตง', part: 'ใต้' }, 
    { id: 23, name: 'ตราด', short: 'ตร', part: 'ตะวันออก' }, 
    { id: 63, name: 'ตาก', short: 'ตก', part: 'ตะวันตก' }, 
    { id: 26, name: 'นครนายก', short: 'นย', part: 'กลาง' }, 
    { id: 73, name: 'นครปฐม', short: 'นฐ', part: 'กลาง' }, 
    { id: 48, name: 'นครพนม', short: 'นพ', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 30, name: 'นครราชสีมา', short: 'นม', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 80, name: 'นครศรีธรรมราช', short: 'นศ', part: 'ใต้' }, 
    { id: 60, name: 'นครสวรรค์', short: 'นว', part: 'กลาง' }, 
    { id: 12, name: 'นนทบุรี', short: 'นบ', part: 'กลาง' }, 
    { id: 96, name: 'นราธิวาส', short: 'นธ', part: 'ใต้' }, 
    { id: 55, name: 'น่าน', short: 'นน', part: 'เหนือ' }, 
    { id: 31, name: 'บุรีรัมย์', short: 'บร', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 97, name: 'บึงกาฬ', short: 'บก', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 13, name: 'ปทุมธานี', short: 'ปท', part: 'กลาง' }, 
    { id: 77, name: 'ประจวบคีรีขันธ์', short: 'ปข', part: 'ตะวันตก' }, 
    { id: 25, name: 'ปราจีนบุรี', short: 'ปจ', part: 'ตะวันออก' }, 
    { id: 94, name: 'ปัตตานี', short: 'ปน', part: 'ใต้' }, 
    { id: 14, name: 'พระนครศรีอยุธยา', short: 'อย', part: 'กลาง' }, 
    { id: 56, name: 'พะเยา', short: 'พย', part: 'เหนือ' }, 
    { id: 82, name: 'พังงา', short: 'พง', part: 'ใต้' }, 
    { id: 93, name: 'พัทลุง', short: 'พท', part: 'ใต้' }, 
    { id: 66, name: 'พิจิตร', short: 'พจ', part: 'กลาง' }, 
    { id: 65, name: 'พิษณุโลก', short: 'พล', part: 'กลาง' }, 
    { id: 76, name: 'เพชรบุรี', short: 'พบ', part: 'ตะวันตก' }, 
    { id: 67, name: 'เพชรบูรณ์', short: 'พช', part: 'กลาง' }, 
    { id: 54, name: 'แพร่', short: 'พร', part: 'เหนือ' }, 
    { id: 83, name: 'ภูเก็ต', short: 'ภก', part: 'ใต้' }, 
    { id: 44, name: 'มหาสารคาม', short: 'มค', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 49, name: 'มุกดาหาร', short: 'มห', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 58, name: 'แม่ฮ่องสอน', short: 'มส', part: 'เหนือ' }, 
    { id: 35, name: 'ยโสธร', short: 'ยส', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 95, name: 'ยะลา', short: 'ยล', part: 'ใต้' }, 
    { id: 45, name: 'ร้อยเอ็ด', short: 'รอ', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 85, name: 'ระนอง', short: 'รน', part: 'ใต้' }, 
    { id: 21, name: 'ระยอง', short: 'รย', part: 'ตะวันออก' }, 
    { id: 70, name: 'ราชบุรี', short: 'รบ', part: 'ตะวันตก' }, 
    { id: 16, name: 'ลพบุรี', short: 'ลบ', part: 'กลาง' }, 
    { id: 52, name: 'ลำปาง', short: 'ลป', part: 'เหนือ' }, 
    { id: 51, name: 'ลำพูน', short: 'ลพ', part: 'เหนือ' }, 
    { id: 42, name: 'เลย', short: 'ลย', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 33, name: 'ศรีสะเกษ', short: 'ศก', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 47, name: 'สกลนคร', short: 'สน', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 90, name: 'สงขลา', short: 'สข', part: 'ใต้' }, 
    { id: 91, name: 'สตูล', short: 'สต', part: 'ใต้' }, 
    { id: 11, name: 'สมุทรปราการ', short: 'สป', part: 'กลาง' }, 
    { id: 75, name: 'สมุทรสงคราม', short: 'สส', part: 'กลาง' }, 
    { id: 74, name: 'สมุทรสาคร', short: 'สค', part: 'กลาง' }, 
    { id: 27, name: 'สระแก้ว', short: 'สก', part: 'ตะวันออก' }, 
    { id: 19, name: 'สระบุรี', short: 'สบ', part: 'กลาง' }, 
    { id: 17, name: 'สิงห์บุรี', short: 'สห', part: 'กลาง' }, 
    { id: 64, name: 'สุโขทัย', short: 'สท', part: 'กลาง' }, 
    { id: 72, name: 'สุพรรณบุรี', short: 'สพ', part: 'กลาง' }, 
    { id: 84, name: 'สุราษฎร์ธานี', short: 'สฎ', part: 'ใต้' }, 
    { id: 32, name: 'สุรินทร์', short: 'สร', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 43, name: 'หนองคาย', short: 'นค', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 39, name: 'หนองบัวลำภู', short: 'นภ', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 15, name: 'อ่างทอง', short: 'อท', part: 'กลาง' }, 
    { id: 37, name: 'อำนาจเจริญ', short: 'อจ', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 41, name: 'อุดรธานี', short: 'อด', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 53, name: 'อุตรดิตถ์', short: 'อต', part: 'เหนือ' }, 
    { id: 61, name: 'อุทัยธานี', short: 'อน', part: 'กลาง' }, 
    { id: 34, name: 'อุบลราชธานี', short: 'อบ', part: 'ตะวันออกเฉียงเหนือ' }, 
    { id: 101, name: 'เบตง', short: 'บต', part: 'ใต้' }
]

export const BANKs = [
    { id: 2, name: 'ธนาคารกรุงเทพ', name_s: 'BBL', color: '#1E4598', icon: 'https://files.un-no.net/static/bank/bbl.svg' }, 
    { id: 4, name: 'ธนาคารกสิกรไทย', name_s: 'KBANK', color: '#128F2D', icon: 'https://files.un-no.net/static/bank/kbank.svg' }, 
    { id: 6, name: 'ธนาคารกรุงไทย', name_s: 'KTB', color: '#1AA5E1', icon: 'https://files.un-no.net/static/bank/ktb.svg' }, 
    { id: 11, name: 'ธนาคารทหารไทย', name_s: 'TMB', color: '#1179BE', icon: 'https://files.un-no.net/static/bank/tmb.svg' }, 
    { id: 14, name: 'ธนาคารไทยพาณิชย์', name_s: 'SCB', color: '#128F2D', icon: 'https://files.un-no.net/static/bank/scb.svg' }, 
    { id: 25, name: 'ธนาคารกรุงศรีอยุธยา', name_s: 'BAY', color: '#FEC43B', icon: 'https://files.un-no.net/static/bank/bay.svg' }, 
    { id: 30, name: 'ธนาคารออมสิน', name_s: 'GSB', color: '#EB198D', icon: 'https://files.un-no.net/static/bank/gsb.svg' }, 
    { id: 33, name: 'ธนาคารอาคารสงเคราะห์', name_s: 'GHB' }, 
    { id: 66, name: 'ธนาคารอิสลามแห่งประเทศไทย', name_s: 'ISALAM' }, 
    { id: 69, name: 'ธนาคารเกียรตินาคิน', name_s: 'KKP' }, 
    { id: 22, name: 'ธนาคารซีไอเอ็มบีไทย', name_s: 'CIMB' }, 
    { id: 67, name: 'ธนาคารทิสโก้', name_s: 'TISCO' }, 
    { id: 65, name: 'ธนาคารธนชาต', name_s: 'TBANK' }, 
    { id: 24, name: 'ธนาคารยูโอบี', name_s: 'UOB' }, 
    { id: 20, name: 'ธนาคารสแตนดาร์ดชาร์เตอร์ด', name_s: 'SCBT' }, 
    { id: 71, name: 'ธนาคารไทยเครดิตเพื่อรายย่อย', name_s: 'TCD' }, 
    { id: 73, name: 'ธนาคารแลนด์ แอนด์ เฮาส์', name_s: 'LHBANK' }, 
    { id: 70, name: 'ธนาคารไอซีบีซี', name_s: 'ICBC' }, 
    { id: 98, name: 'ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย', name_s: 'SME' }, 
    { id: 34, name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร', name_s: 'BAAC' }, 
    { id: 35, name: 'ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย', name_s: 'EXIM' }
]

export const BOOK_VERIFYs = [
    { group: 'book', name: 'agent', text: 'ตัวแทน', option: 'id' }, 
    { group: 'book', name: 'employee', text: 'พนักงาน', option: 'id' }, 
    { group: 'book', name: 'express', text: 'ใบเสร็จเร่งด่วน' }, 
    { group: 'customer', name: 'customer', text: 'ลูกค้า' }, 
    { group: 'customer', name: 'dateLicenseExpire', text: 'วันหมดอายุใบขับขี่ลูกค้า' }, 
    { group: 'customer', name: 'customerPay', text: 'ผู้ผ่อน' }, 
    { group: 'vehicle', name: 'vehicleDate', text: 'วันจดทะเบียน' }, 
    { group: 'vehicle', name: 'vehicleLicense', text: 'ทะเบียนรถ' }, 
    { group: 'vehicle', name: 'vehicleProvice', text: 'จังหวัด' }, 
    { group: 'vehicle', name: 'metaVehicle', text: 'ยี่ห้อ/รุ่น/ปี/รุ่นย่อย' }, 
    { group: 'vehicle', name: 'vehicleNumber', text: 'เลขตัวถัง' }, 
    { group: 'vehicle', name: 'vehicleNumberEngine', text: 'เลขเครื่องยนต์' }, 
    { group: 'insurance', name: 'company', text: 'บริษัท' }, 
    { group: 'insurance', name: 'workcode', text: 'รหัสแจ้งงาน' }, 
    { group: 'insurance', name: 'metaInsuretype', text: 'ประเภทประกัน' }, 
    { group: 'insurance', name: 'moneyFund', text: 'ทุนประกันภัย' }, 
    { group: 'insurance', name: 'vehicleUsage', text: 'ประเภทการใช้รถ' }, 
    { group: 'insurance', name: 'detail', text: 'รายการตกแต่งเพิ่มเติม' }, 
    { group: 'insurance', name: 'money', text: 'ราคารายการตกแต่ง' }, 
    { group: 'insurance', name: 'options', text: 'อุปกรณ์เพิ่มเติมพิเศษอื่น ๆ' }, 
    { group: 'insurance', name: 'insuranceNumber', text: 'เลขที่กรมธรรม์' }, 
    { group: 'insurance', name: 'moneyDeduct', text: 'ดีดัก' }, 
    { group: 'insurance', name: 'insuranceIssue', text: 'การออกกรมธรรม์' }, 
    { group: 'insurance', name: 'coverage', text: 'ความคุ้มครอง' }, 
    { group: 'pay', name: '__payMeta', text: 'ประเภทการชำระ' }, 
    { group: 'pay', name: 'moneyInsure', text: 'เบี้ยประกันภัยสุทธิ' }, 
    { group: 'pay', name: 'moneyDiscount', text: 'ส่วนลดลูกค้า' }, 
    { group: 'pay', name: 'moneyFee', text: 'ค่าธรรมเนียม' }, 
    { group: 'pay', name: 'moneyInterest', text: 'ดอกเบี้ย' }, 
    { group: 'pay', name: 'note', text: 'หมายเหตุ' }, 
    { group: 'pay', name: 'moneyFine', text: 'ค่าปรับ' }, 
    { group: 'files', name: 'vehicle_f', text: 'หน้า' }, 
    { group: 'files', name: 'vehicle_b', text: 'หลัง' }, 
    { group: 'files', name: 'vehicle_r', text: 'ข้างขวา' }, 
    { group: 'files', name: 'vehicle_l', text: 'ข้างซ้าย' }, 
    { group: 'files', name: 'vehicle_fr', text: 'มุมหน้าขวา' }, 
    { group: 'files', name: 'vehicle_fl', text: 'มุมหน้าซ้าย' }, 
    { group: 'files', name: 'vehicle_br', text: 'มุมหลังขวา' }, 
    { group: 'files', name: 'vehicle_bl', text: 'มุมหลังซ้าย' }, 
    { group: 'files', name: 'vehicle_license', text: 'ทะเบียนรถ' }, 
    { group: 'files', name: 'tax', text: 'ภาษี' }, 
    { group: 'files', name: 'quotation', text: 'ใบเสนอราคา' }, 
    { group: 'files', name: 'installment', text: 'สัญญา' }, 
    { group: 'files', name: 'poa', text: 'ใบมอบอำนาจ' }, 
    { group: 'files', name: 'cancel', text: 'หนังสือยกเลิก' }, 
    { group: 'files', name: 'deduct', text: 'หนังสือดีดักท์' }, 
    { group: 'files', name: 'checkup_note', text: 'บันทึกตรวจสภาพรถ' }, 
    { group: 'files', name: 'request', text: 'คำขอทำประกัน' }, 
    { group: 'files', name: 'checkup', text: 'ตรวจสภาพรถ' }, 
    { group: 'files', name: 'insurance', text: 'กรมธรรม์' }, 
    { group: 'files', name: 'endorse', text: 'สลักหลัง' }, 
    { group: 'files', name: 'addon', text: 'อุปกรณ์ต่อเติม' }, 
    { group: 'files', name: 'other', text: 'ไฟล์และภาพอื่น ๆ' }
]

export const CALL_STATUSs = [
    { id: 'NO ANSWER', name: 'ไม่รับสาย', color: 'grey' }, 
    { id: 'ANSWERED', name: 'รับสาย', color: 'green' }, 
    { id: 'BUSY', name: 'สายไม่ว่าง', color: 'orange' }
]

export const CALL_TYPEs = [
    { id: 'Outbound', name: 'โทรออก' }, 
    { id: 'Inbound', name: 'โทรเข้า' }
]

export const CAR_BRANDs = [
    { name: 'TOYOTA', types: ['รถเก๋ง', 'กระบะ 4 ประตู', 'รถตู้', 'รถยนต์บรรทุก', 'กระบะ 2 ประตู'] }, 
    { name: 'HONDA', types: ['รถเก๋ง', 'รถจักรยานยนต์'] }, 
    { name: 'ISUZU', types: ['กระบะ 2 ประตู', 'รถเก๋ง', 'รถยนต์ลากจูง', 'รถยนต์บรรทุก', 'กระบะ 4 ประตู'] }, 
    { name: 'MITSUBISHI', types: ['กระบะ 4 ประตู', 'รถยนต์บรรทุก', 'รถตู้', 'กระบะ 2 ประตู', 'รถยนต์ลากจูง', 'รถเก๋ง'] }, 
    { name: 'NISSAN', types: ['รถยนต์บรรทุก', 'รถเก๋ง', 'กระบะ 2 ประตู', 'กระบะ 4 ประตู', 'รถยนต์ลากจูง', 'รถตู้'] }, 
    { name: 'MAZDA', types: ['รถเก๋ง', 'กระบะ 2 ประตู', 'กระบะ 4 ประตู', 'รถจักรยานยนต์'] }, 
    { name: 'FORD', types: ['รถเก๋ง', 'กระบะ 2 ประตู', 'กระบะ 4 ประตู'] }, 
    { name: 'SUZUKI', types: ['รถจักรยานยนต์', 'รถเก๋ง', 'กระบะ 2 ประตู', 'รถตู้'] }, 
    { name: 'MG', types: ['กระบะ 2 ประตู', 'รถตู้', 'รถเก๋ง'] }, 
    { name: 'CHEVROLET', types: ['กระบะ 4 ประตู', 'กระบะ 2 ประตู', 'รถเก๋ง'] }, 
    { name: 'MERCEDES-BENZ', types: ['รถเก๋ง', 'รถตู้'] }, 
    { name: 'BMW', types: ['รถเก๋ง', 'รถจักรยานยนต์'] }, 
    { name: 'BYD', types: ['รถเก๋ง'] }, 
    { name: 'HYUNDAI', types: ['รถตู้', 'รถบรรทุก', 'รถเก๋ง'] }, 
    { name: 'HINO', types: ['รถยนต์ลากจูง', 'รถยนต์บรรทุก', 'รถบรรทุก'] }, 
    { name: 'VOLVO', types: ['รถเก๋ง'] }, 
    { name: 'HAVAL', types: ['รถเก๋ง'] }, 
    { name: 'NETA', types: ['รถเก๋ง'] }, 
    { name: 'YAMAHA', types: ['รถจักรยานยนต์'] }, 
    { name: 'SUBARU', types: ['รถเก๋ง'] }, 
    { name: 'KIA', types: ['รถเก๋ง', 'รถตู้', 'รถบรรทุก'] }, 
    { name: 'PROTON', types: ['รถเก๋ง'] }, 
    { name: 'PORSCHE', types: ['01', '03'] }, 
    { name: 'VESPA', types: ['รถจักรยานยนต์'] }, 
    { name: 'AUDI', types: ['รถเก๋ง'] }, 
    { name: 'LAMBORGHINI', types: ['03'] }, 
    { name: 'WULING', types: ['รถยนต์บรรทุก', 'รถเก๋ง', 'รถตู้'] }, 
    { name: 'SSANG YONG', types: ['รถเก๋ง'] }, 
    { name: 'ASTON MARTIN', types: ['03'] }, 
    { name: 'BENELLI', types: ['รถจักรยานยนต์'] }, 
    { name: 'BENTLEY', types: ['03'] }, 
    { name: 'CHERY', types: ['รถเก๋ง'] }, 
    { name: 'CHRYSLER', types: ['รถเก๋ง'] }, 
    { name: 'CITROEN', types: ['รถเก๋ง', 'รถตู้'] }, 
    { name: 'HUMMER', types: ['01'] }, 
    { name: 'HUSQVARNA', types: ['รถจักรยานยนต์'] }, 
    { name: 'HYOSUNG', types: ['รถจักรยานยนต์'] }, 
    { name: 'INDIAN', types: ['รถจักรยานยนต์'] }, 
    { name: 'IVECO', types: ['รถยนต์บรรทุก'] }, 
    { name: 'JAC', types: ['รถยนต์บรรทุก'] }, 
    { name: 'JAGUAR', types: ['รถเก๋ง'] }, 
    { name: 'JBC', types: ['รถยนต์บรรทุก'] }, 
    { name: 'JEEP', types: ['รถเก๋ง'] }, 
    { name: 'KAWASAKI', types: ['รถจักรยานยนต์'] }, 
    { name: 'KEEWAY', types: ['รถจักรยานยนต์'] }, 
    { name: 'KTM', types: ['รถจักรยานยนต์'] }, 
    { name: 'LAMBRETTA', types: ['รถจักรยานยนต์'] }, 
    { name: 'LAND ROVER', types: ['01', 'รถเก๋ง'] }, 
    { name: 'LEABON', types: ['รถเก๋ง'] }, 
    { name: 'LEXUS', types: ['รถตู้', 'รถเก๋ง'] }, 
    { name: 'LIFAN', types: ['รถจักรยานยนต์'] }, 
    { name: 'MALAGUTI', types: ['รถจักรยานยนต์'] }, 
    { name: 'MAN', types: ['รถยนต์บรรทุก'] }, 
    { name: 'MAXUS', types: ['รถตู้'] }, 
    { name: 'MINI', types: ['รถตู้', 'รถเก๋ง'] }, 
    { name: 'MITSUOKA', types: ['รถเก๋ง'] }, 
    { name: 'MOTOGUZZI', types: ['รถจักรยานยนต์'] }, 
    { name: 'MOTO PARILLA', types: ['รถจักรยานยนต์'] }, 
    { name: 'MV AGUSTA', types: ['รถจักรยานยนต์'] }, 
    { name: 'NAZA', types: ['รถเก๋ง'] }, 
    { name: 'NORTON', types: ['รถจักรยานยนต์'] }, 
    { name: 'OLDSCHOOL', types: ['รถจักรยานยนต์'] }, 
    { name: 'OPEL', types: ['รถเก๋ง'] }, 
    { name: 'ORA', types: ['รถเก๋ง'] }, 
    { name: 'PERODUA', types: ['รถเก๋ง'] }, 
    { name: 'PEUGEOT', types: ['รถตู้', 'รถจักรยานยนต์', 'รถเก๋ง'] }, 
    { name: 'PIAGGIO', types: ['รถจักรยานยนต์'] }, 
    { name: 'POCCO', types: ['รถเก๋ง'] }, 
    { name: 'POLARSUN', types: ['รถตู้'] }, 
    { name: 'RANGE ROVER', types: ['01'] }, 
    { name: 'ROLLS-ROYCE', types: ['03'] }, 
    { name: 'ROYAL ALLOY', types: ['รถจักรยานยนต์'] }, 
    { name: 'ROYAL ENFIELD', types: ['รถจักรยานยนต์'] }, 
    { name: 'RYUKA', types: ['รถจักรยานยนต์'] }, 
    { name: 'SAAB', types: ['รถเก๋ง'] }, 
    { name: 'SAMMITR', types: ['กระบะ 4 ประตู', 'รถยนต์บรรทุก'] }, 
    { name: 'SANIT', types: ['รถเก๋ง'] }, 
    { name: 'SANY', types: ['รถยนต์บรรทุก'] }, 
    { name: 'SCANIA', types: ['รถยนต์บรรทุก', 'รถยนต์ลากจูง'] }, 
    { name: 'SCOMADI', types: ['รถจักรยานยนต์'] }, 
    { name: 'SEAT', types: ['รถเก๋ง'] }, 
    { name: 'SHACMAN', types: ['รถยนต์บรรทุก', 'รถยนต์ลากจูง'] }, 
    { name: 'SINOTRUK', types: ['รถยนต์บรรทุก', 'รถยนต์ลากจูง'] }, 
    { name: 'SKODA', types: ['รถเก๋ง'] }, 
    { name: 'SOKON', types: ['รถยนต์บรรทุก', 'รถตู้', 'รถบรรทุก'] }, 
    { name: 'SONTES', types: ['รถจักรยานยนต์'] }, 
    { name: 'SPCN', types: ['รถจักรยานยนต์'] }, 
    { name: 'STALLION', types: ['รถจักรยานยนต์'] }, 
    { name: 'STALLIONS', types: ['รถจักรยานยนต์'] }, 
    { name: 'SYM', types: ['รถจักรยานยนต์'] }, 
    { name: 'TADANO', types: ['รถยนต์บรรทุก'] }, 
    { name: 'TAKANO', types: ['กระบะ 2 ประตู'] }, 
    { name: 'TATA', types: ['รถเก๋ง', 'กระบะ 4 ประตู', 'รถยนต์บรรทุก', 'กระบะ 2 ประตู', 'รถบรรทุก'] }, 
    { name: 'TESLA', types: ['รถเก๋ง'] }, 
    { name: 'THAINA', types: ['รถยนต์บรรทุก'] }, 
    { name: 'TR', types: ['รถเก๋ง'] }, 
    { name: 'TRAILER', types: ['รถยนต์บรรทุก'] }, 
    { name: 'TRIUMPH', types: ['รถจักรยานยนต์'] }, 
    { name: 'TUKTUK', types: ['รถเก๋ง'] }, 
    { name: 'UD', types: ['รถยนต์บรรทุก'] }, 
    { name: 'URAL', types: ['รถจักรยานยนต์'] }, 
    { name: 'VICTORY', types: ['รถจักรยานยนต์'] }, 
    { name: 'VOLKSWAGEN', types: ['รถเก๋ง', 'รถตู้', 'กระบะ 4 ประตู'] }, 
    { name: 'VOLT', types: ['รถเก๋ง'] }, 
    { name: 'V-TWIN', types: ['รถจักรยานยนต์'] }, 
    { name: 'ZERO', types: ['รถจักรยานยนต์'] }
]

export const CAR_TYPEs = [
    '01', 
    '03', 
    'กระบะ 2 ประตู', 
    'กระบะ 4 ประตู', 
    'รถเก๋ง', 
    'รถจักรยานยนต์', 
    'รถตู้', 
    'รถบรรทุก', 
    'รถยนต์บรรทุก', 
    'รถยนต์ลากจูง'
]

export const COMPANY_BANKs = [
    { id: '694-2-38541-5 ', name: 'ธนาคารไทยพาณิชย์ : 694-2-38541-5 ', payways: ['เงินสด', 'เช็ค', 'สแกนจ่าย', 'สแกนจ่ายSCB'] }, 
    { id: '055-3-37484-7', name: 'ธนาคารกสิกรไทย : 055-3-37484-7', payways: ['โอนเงิน', 'K-CASH'] }, 
    { id: '058-3-33959-0', name: 'ธนาคารกสิกรไทย : 058-3-33959-0', payways: ['บัตรเครดิต', 'pay.sn'] }
]

export const COMPANY_CAR_CHECKUPs = [
    { id: 0, name: 'บริษัทประกันเป็นผู้ตรวจสภาพรถ' }, 
    { id: 1, name: 'ลูกค้าเป็นคนตรวจ' }
]

export const COMPANYs = [
    { id: 1008, name: 'บจก. กลางคุ้มครองผู้ประสบภัยจากรถ', workcodes: [        { code: '2081997003028000', name: 'บจก. ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1024, name: 'บจก. ไทยไพบูลย์ประกันภัย', workcodes: [        { code: '02018678', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1009, name: 'บมจ. กรุงเทพประกันภัย', workcodes: [        { code: '6589', name: 'บจ.ออนไลน์โบรคเกอร์ประกันภัย' },         { code: '30279-01', name: 'อนัญญา  คงเดช' }
    ] }, 
    { id: 1002, name: 'บมจ. คุ้มภัยโตเกียวมารีนประกันภัย', workcodes: [        { code: 'B3J0005', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1014, name: 'บมจ. ชับบ์สามัคคีประกันภัย', workcodes: [        { code: '402040189', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1031, name: 'บมจ. ซมโปะประกันภัย', workcodes: [        { code: 'BRO1369', name: '' }
    ] }, 
    { id: 1016, name: 'บมจ. ทิพยประกันภัย', workcodes: [        { code: '2021620004', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1027, name: 'บมจ. ธนชาตประกันภัย', workcodes: [        { code: '1002965', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1020, name: 'บมจ. นวกิจประกันภัย', workcodes: [        { code: 'ออนไลน์0004', name: 'บจก.ออนไลน์โบรคเกอร์' }
    ] }, 
    { id: 1015, name: 'บมจ. มิตรแท้ประกันภัย', workcodes: [        { code: 'MT205737', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย ' }
    ] }, 
    { id: 1010, name: 'บมจ. วิริยะประกันภัย', workcodes: [        { code: '15858', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1003, name: 'บมจ. สินมั่นคงประกันภัย', workcodes: [        { code: '0308269', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1025, name: 'บมจ. อลิอันซ์ อยุธยา ประกันภัย', workcodes: [        { code: '005322', name: 'บจก.ออนไลน์โบรคเกอร์ประกัยภัย' }
    ] }, 
    { id: 1028, name: 'บมจ. อินทรประกันภัย', workcodes: [        { code: '1110130037', name: 'บจ.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1004, name: 'บมจ. เทเวศประกันภัย', workcodes: [        { code: '90005193', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1018, name: 'บมจ. เมืองไทยประกันภัย', workcodes: [        { code: '74000646', name: 'บจก.ออนไลน์โบรคเกอร์' }
    ] }, 
    { id: 1026, name: 'บมจ. เออร์โกประกันภัย', workcodes: [        { code: 'C4712', name: 'บจก.ออนไลน์โบรคเกอร์' }
    ] }, 
    { id: 1011, name: 'บมจ. เอ็ม เอส ไอ จี ประกันภัย', workcodes: [        { code: 'RSA48817', name: 'บจก. ออนไลน์โบรคเกอร์ประกันภัย' },         { code: 'BCA48818', name: 'บจก. ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1029, name: 'บมจ. แอกซ่าประกันภัย', workcodes: [        { code: 'NB010', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1013, name: 'บมจ. แอลเอ็มจี ประกันภัย', workcodes: [        { code: 'BRO1234', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1023, name: 'บมจ. ไอโออิ กรุงเทพ ประกันภัย', workcodes: [        { code: '61074-00', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1017, name: 'บจก. ทีเอ็ม โบรคเกอร์', workcodes: [        { code: '2202295', name: 'บจก.ออนไลน์โบรคเกอร์' }
    ] }, 
    { id: 1030, name: 'บจก. ไทยวิวัฒน์', workcodes: [] }, 
    { id: 1005, name: 'บมจ. สหมงคลประกันภัย', workcodes: [        { code: 'ช1000/15/4001', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1006, name: 'บมจ. อาคเนย์ประกันภัย', workcodes: [        { code: '10130037', name: 'บจก.ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1001, name: 'บมจ. เดอะ วัน ประกันภัย', workcodes: [        { code: '1202814', name: 'ออนไลน์โบรคเกอร์ประกันภัย' }
    ] }, 
    { id: 1007, name: 'บมจ. เอเชียประกันภัย 1950', workcodes: [] }, 
    { id: 1022, name: 'บมจ.ฟอลคอนประกันภัย', workcodes: [        { code: '2202295', name: 'บจก.ออนไลน์โบรคเกอร์' }
    ] }, 
    { id: 1021, name: 'บมจ.แอกซ่าประกันภัย', workcodes: [        { code: 'NB010', name: '' }
    ] }, 
    { id: 1019, name: 'บมจ.ไทยศรีประกันภัย', workcodes: [        { code: '2202295', name: 'บจก.ออนไลน์โบรคเกอร์' }
    ] }
]

export const CONTACT_WAYs = [
    { id: '', name: 'ไม่ระบุ' }, 
    { id: 'tel', name: 'เบอร์โทร' }, 
    { id: 'mobile', name: 'เบอร์มือถือ' }, 
    { id: 'email', name: 'อีเมล' }, 
    { id: 'line', name: 'LINE' }
]

export const CUSTOMER_JOBs = [
    { id: 0, name: 'ไม่ระบุ' }, 
    { id: 1, name: 'พนักงานออฟฟิศ' }, 
    { id: 2, name: 'พนักงานราชการ' }, 
    { id: 3, name: 'พนักงานโรงงาน' }, 
    { id: 4, name: 'ค้าขาย' }, 
    { id: 5, name: 'รับส่งสินค้า' }, 
    { id: 6, name: 'อาชีพอิสระ' }
]

export const CUSTOMER_SALARY_OPTIONs = [
    { id: 1, name: 'ไม่เกิน 9000' }, 
    { id: 9000, name: '9000 - 15000 บาท' }, 
    { id: 15000, name: '15000 - 25000 บาท' }, 
    { id: 25000, name: '25000 - 35000 บาท' }, 
    { id: 35000, name: 'มากกว่า 35000 บาท' }
]

export const CUSTOMER_STATEs = [
    { id: 0, name: 'ลูกค้าติดต่อเข้ามา', icon: 'phone-volume', color: 'grey-blue', fromDoc: 0 }, 
    { id: 10, name: 'คนให้ข้อมูล', icon: 'file-invoice-dollar', color: 'sky', fromDoc: 9 }, 
    { id: 11, name: 'คนไม่ให้ข้อมูล', icon: 'feather', color: 'pink', fromDoc: 1, fromDocState: 0 }, 
    { id: 20, name: 'ผู้สนใจ', icon: 'file-signature', color: 'red', fromDoc: 10 }, 
    { id: 21, name: 'ว่าที่ลูกค้า', icon: 'cauldron', color: 'purple', fromDoc: 40 }, 
    { id: 30, name: 'ไม่ซื้อสินค้า', icon: 'cash-register', color: 'orange', fromDoc: 1, fromDocState: 40 }, 
    { id: 100, name: 'ลูกค้า', icon: 'clipboard-check', color: 'green', fromDoc: 100 }
]

export const DOCUMENT_FOLLOW_RESONs = [
    'เงินไม่พอ', 
    'รอเงินออก', 
    'หาเงินไม่ทัน', 
    'ป่วย', 
    'ธนาคารมีปัญหา', 
    'ขอเลื่อน', 
    'อยู่ต่างประเทศ', 
    'อยู่ต่างจังหวัด', 
    'อื่นๆ'
]

export const DOCUMENT_FOLLOW_STATEs = [
    { id: 0, name: 'ไม่มี' }, 
    { id: 2, name: 'ติดต่อไม่ได้' }, 
    { id: 4, name: 'นัดโอน' }, 
    { id: 5, name: 'นัดที่เหลือ' }
]

export const DOCUMENT_ORDER_APPROVEs = [
    { id: -1, name: 'รออนุมัติ', color: 'grey' }, 
    { id: 0, name: 'อนุมัติแล้ว', color: 'green' }, 
    { id: 1, name: 'ไม่อนุมัติ', color: 'red' }
]

export const DOCUMENT_ORDER_CONTRACTs = [
    { id: 300, name: 'ตรวจสัญญา', color: 'red' }, 
    { id: 310, name: 'แก้ไขตรวจสัญญา', color: 'green' }
]

export const DOCUMENT_ORDER_FOLLOW_STATEs = [
    { id: 1, name: 'นัดหมาย/ติดตาม' }, 
    { id: 2, name: 'เลื่อนนัด' }, 
    { id: 3, name: 'เสร็จสิ้น' }
]

export const DOCUMENT_ORDER_OFFER_BROKERs = [
    { id: 1, name: 'เงินติดล้อ' }, 
    { id: 2, name: 'รู้ใจ' }, 
    { id: 3, name: 'ศูนย์รถ' }, 
    { id: 4, name: 'ราคาต่ออายุบริษัทเดิม' }, 
    { id: 5, name: 'อื่นๆ' }
]

export const DOCUMENT_ORDER_OLD_COMPANY_EXPIRE_STATEs = [
    { id: 0, name: 'ระบุวันหมดอายุ' }, 
    { id: 1, name: 'ยังไม่หมด จำวันที่ไม่ได้' }, 
    { id: 2, name: 'ขาดต่อแล้ว จำวันที่ไม่ได้' }
]

export const DOCUMENT_ORDER_OLD_COMPANY_STATEs = [
    { id: 1, name: 'ไม่ทราบ' }, 
    { id: 2, name: 'จำไม่ได้' }
]

export const DOCUMENT_ORDER_PAYMENT_METHODs = [
    { id: 1, name: 'เต็มจำนวน' }, 
    { id: 2, name: 'ผ่อนชำระ' }
]

export const DOCUMENT_ORDER_STATEs = [
    { id: 0, name: 'เสนอราคา', color: 'orange' }, 
    { id: 10, name: 'ยื่นข้อเสนอ', color: 'brown' }, 
    { id: 100, name: 'คำสั่งซื้อ', color: 'blue' }, 
    { id: 200, name: 'อนุมัติ', color: 'blue' }, 
    { id: 290, name: 'รอชำระเงิน', color: 'blue' }, 
    { id: 300, name: 'จ่ายแล้ว', color: 'blue' }, 
    { id: 400, name: 'รอบันทึกรับแจ้ง', color: 'blue' }, 
    { id: 500, name: 'แจ้งความคุ้มครอง', color: 'purple' }, 
    { id: 900, name: 'จบ', color: 'purple' }
]

export const DOCUMENT_ORDER_VEHICLE_USAGEs = [
    { id: 1, name: 'ส่วนบุคคล' }, 
    { id: 2, name: 'ใช้เพื่อการพาณิชย์' }, 
    { id: 3, name: 'ใช้เพื่อการพาณิชย์พิเศษ' }, 
    { id: 4, name: 'รับจ้างสาธารณะ' }, 
    { id: 5, name: 'อื่นๆ' }
]

export const DOCUMENT_STATEs = [
    { id: 0, name: 'ไม่มี', icon: 'ellipsis-h', color: 'grey' }, 
    { id: 1, name: 'ไม่มีการพูดคุย/ทักแล้วเงียบ', icon: 'comment-times', color: 'grey-blue' }, 
    { id: 9, name: 'รอข้อมูล/เอกสารเช็คเบี้ย', icon: 'watch', color: 'sky' }, 
    { id: 10, name: 'เสนอราคาแล้ว', icon: 'file-invoice-dollar', color: 'sky' }, 
    { id: 20, name: 'รอเอกสารทำประกัน', icon: 'feather', color: 'pink' }, 
    { id: 40, name: 'รอชำระเงิน', icon: 'history', color: 'purple' }, 
    { id: 45, name: 'อยู่ระหว่างทำสัญญาและแจ้งความคุ้มครอง', icon: 'cauldron', color: 'purple' }, 
    { id: 100, name: 'ซื้อแล้ว', icon: 'clipboard-check', color: 'green' }, 
    { id: 90, name: 'ลูกค้าเปลี่ยนใจไม่ซื้อ', icon: 'times', color: 'red' }
]

export const FIELD_TYPEs = [
    { id: 1, name: 'text' }, 
    { id: 2, name: 'select' }, 
    { id: 3, name: 'checkbox' }, 
    { id: 4, name: 'radio' }, 
    { id: 5, name: 'date' }, 
    { id: 6, name: 'photo' }, 
    { id: 7, name: 'file' }, 
    { id: 8, name: 'vehicle' }
]

export const INSURETYPEs = [
    { id: 11, name: 'ภาคสมัครใจ', group: 'MOTOR' }, 
    { id: 12, name: 'ภาคบังคับ', group: 'MOTOR' }, 
    { id: 21, name: 'อัคคีภัย', group: 'NON-MOTOR' }, 
    { id: 22, name: 'เบ็ดเตล็ด', group: 'NON-MOTOR' }, 
    { id: 23, name: 'ทางทะเลและขนส่ง', group: 'NON-MOTOR' }
]

export const META_CUSTOMER_TYPEs = [
    { id: 1012, name: 'เจ้าของบริษัท' }, 
    { id: 1001, name: 'ญาติและเพื่อน' }, 
    { id: 1011, name: 'พนักงาน' }, 
    { id: 1010, name: 'ลูกค้า VIP' }, 
    { id: 1004, name: 'ลูกค้าตัวแทน' }, 
    { id: 1005, name: 'ลูกค้าตัวแทนอินเตอร์เน็ต' }, 
    { id: 1002, name: 'ลูกค้าหน้าร้าน' }, 
    { id: 1003, name: 'ลูกค้าอินเตอร์เน็ต' }
]

export const META_CUSTOMERs = [
    { id: 1012, name: 'เจ้าของบริษัท' }, 
    { id: 1001, name: 'ญาติและเพื่อน' }, 
    { id: 1011, name: 'พนักงาน' }, 
    { id: 1010, name: 'ลูกค้า VIP' }, 
    { id: 1004, name: 'ลูกค้าตัวแทน' }, 
    { id: 1005, name: 'ลูกค้าตัวแทนอินเตอร์เน็ต' }, 
    { id: 1002, name: 'ลูกค้าหน้าร้าน' }, 
    { id: 1003, name: 'ลูกค้าอินเตอร์เน็ต' }
]

export const META_INSURETYPEs = [
    { id: 1003, group: 'ภาคสมัครใจ', name: 'ป.1' }, 
    { id: 1028, group: 'ภาคสมัครใจ', name: 'ป.1+' }, 
    { id: 1004, group: 'ภาคสมัครใจ', name: 'ป.2' }, 
    { id: 1005, group: 'ภาคสมัครใจ', name: 'ป.2+' }, 
    { id: 1006, group: 'ภาคสมัครใจ', name: 'ป.3' }, 
    { id: 1007, group: 'ภาคสมัครใจ', name: 'ป.3+' }, 
    { id: 1001, group: 'ภาคบังคับ', name: 'พรบ. จยย.' }, 
    { id: 1025, group: 'ภาคบังคับ', name: 'พรบ. โดยสาร' }, 
    { id: 1016, group: 'ภาคบังคับ', name: 'พรบ. แทรกเตอร์' }, 
    { id: 1010, group: 'ภาคบังคับ', name: 'พรบ. รถกระบะ' }, 
    { id: 1002, group: 'ภาคบังคับ', name: 'พรบ. รถเก๋ง / กระบะ 4 ประตู' }, 
    { id: 1014, group: 'ภาคบังคับ', name: 'พรบ. รถตู้' }, 
    { id: 1022, group: 'ภาคบังคับ', name: 'พรบ. รถบด' }, 
    { id: 1011, group: 'ภาคบังคับ', name: 'พรบ. รถบรรทุก' }, 
    { id: 1013, group: 'ภาคบังคับ', name: 'พรบ. รถบรรทุกน้ำมันเชื้อเพลิง แก๊ส' }, 
    { id: 1026, group: 'ภาคบังคับ', name: 'พรบ. รถพ่วง' }, 
    { id: 1017, group: 'ภาคบังคับ', name: 'พรบ. สองแถว' }, 
    { id: 1020, group: 'อัคคีภัย', name: 'ธุรกิจขนาดย่อม' }, 
    { id: 1021, group: 'อัคคีภัย', name: 'บ้านมั่นคง' }, 
    { id: 1009, group: 'อัคคีภัย', name: 'อัคคีภัย' }, 
    { id: 1012, group: 'เบ็ดเตล็ด', name: 'โควิค' }, 
    { id: 1018, group: 'เบ็ดเตล็ด', name: 'โจรกรรม' }, 
    { id: 1024, group: 'เบ็ดเตล็ด', name: 'เบ็ดเตล็ด' }, 
    { id: 1015, group: 'เบ็ดเตล็ด', name: 'ประกันการเดินทาง' }, 
    { id: 1019, group: 'ทางทะเลและขนส่ง', name: 'ขนส่งสินค้า' }, 
    { id: 1027, group: 'ภาคสมัครใจ', name: 'ค่าจองสิทธิโปรโมชั่น' }, 
    { id: 1008, group: 'ภาคบังคับ', name: 'ป.4' }
]

export const META_PAYLOTs = [
    { id: 100039, name: '10 เดือน', conditions: { companys: [1016, 1002, 1023, 1011, 1018, 1020, 1025, 1004, 1009, 1014], customers: [1003] } }, 
    { id: 100040, name: '10 เดือน งวด 1-2 15% ( กลุ่ม 1 )', conditions: { companys: [1025, 1002, 1016, 1020, 1009, 1023, 1018, 1014, 1004] } }, 
    { id: 100046, name: '10 เดือน งวด 1-2 15% ( กลุ่ม 2 )', conditions: { companys: [1010, 1013, 1026, 1028, 1002, 1025, 1016, 1020, 1009, 1023, 1018, 1014, 1004] } }, 
    { id: 100052, name: '10 เดือน งวดแรก 25%', conditions: { companys: [1025, 1002, 1004, 1016, 1020, 1009, 1023, 1018, 1011, 1010] } }, 
    { id: 100030, name: '10 เดือน งวดแรก 30%', conditions: { companys: [1030, 1002, 1016, 1025, 1018, 1029, 1023, 1013, 1011, 1028, 1010, 1027, 1009, 1014, 1020, 1004, 1026, 1008] } }, 
    { id: 100005, name: '10 เดือน งวดแรก 30% เชิงพาณิชย์', conditions: { companys: [1030, 1016, 1025, 1018, 1029, 1023, 1013, 1011, 1028, 1027, 1002, 1020, 1004, 1026, 1008, 1009] } }, 
    { id: 100049, name: '10 เดือน งวดแรก 35%', conditions: { companys: [1014, 1027, 1010, 1028, 1011, 1013, 1023, 1026, 1004, 1020, 1002, 1016, 1009, 1025, 1018, 1029, 1008], customers: [1001, 1005, 1012, 1002, 1011, 1010, 1007, 1004, 1006, 1003] } }, 
    { id: 100047, name: '11 เดือน', conditions: { companys: [1030, 1002, 1016, 1025, 1018, 1029, 1023, 1013, 1011, 1010, 1027, 1009, 1014, 1020, 1004, 1026, 1008, 1028] } }, 
    { id: 100044, name: '12 เดือน', conditions: { companys: [1030, 1002, 1016, 1025, 1018, 1029, 1023, 1013, 1011, 1010, 1027, 1009, 1014, 1020, 1004, 1026, 1008] } }, 
    { id: 100050, name: '20 งวด', conditions: { companys: [1025, 1002, 1004, 1016, 1020, 1011, 1023, 1018, 1024] } }, 
    { id: 100041, name: '3 เดือน', conditions: { companys: [1030, 1002, 1016, 1025, 1018, 1029, 1023, 1013, 1011, 1028, 1010, 1027, 1009, 1014, 1020, 1004, 1026, 1008, 1015, 1024] } }, 
    { id: 100019, name: '4 เดือน', conditions: { companys: [1002, 1030, 1016, 1015, 1025, 1018, 1029, 1023, 1013, 1011, 1028, 1010, 1027, 1009, 1014, 1020, 1003, 1004, 1026, 1008, 1024] } }, 
    { id: 100056, name: '5 เดือน', conditions: { companys: [1009, 1024, 1002, 1014, 1027, 1010, 1028, 1026, 1029, 1018, 1025, 1015, 1016, 1020, 1004, 1011, 1023] } }, 
    { id: 100027, name: '6 เดือน', conditions: { companys: [1030, 1002, 1016, 1025, 1018, 1029, 1023, 1013, 1011, 1028, 1010, 1027, 1009, 1020, 1004, 1026, 1008, 1024] } }, 
    { id: 100032, name: '6 เดือน งวดแรก 25%', conditions: { companys: [1030, 1002, 1016, 1025, 1018, 1029, 1023, 1013, 1011, 1028, 1010, 1027, 1009, 1020, 1004, 1026, 1008] } }, 
    { id: 100003, name: '6 เดือน งวดแรก 30%', conditions: { companys: [1030, 1002, 1016, 1025, 1018, 1029, 1023, 1013, 1028, 1010, 1011, 1027, 1009, 1014, 1020, 1004, 1026, 1008] } }, 
    { id: 100053, name: '6 เดือน งวดแรก 35%', conditions: { companys: [1014, 1029, 1026] } }, 
    { id: 100054, name: '7 เดือน', conditions: { companys: [1024, 1014, 1027, 1010, 1004, 1011, 1013, 1009, 1028, 1008, 1023, 1029, 1018, 1003, 1020, 1031, 1016, 1015, 1025, 1026, 1002] } }, 
    { id: 100018, name: 'ชำระเต็มจำนวน ( เงินสด )', conditions: { companys: [1030, 1002, 1016, 1015, 1025, 1018, 1029, 1011, 1028, 1010, 1027, 1009, 1014, 1003, 1004, 1026, 1008, 1023, 1013, 1024, 1020, 1031] } }, 
    { id: 100055, name: 'ชำระเต็มจำนวน ( เงินสด ) 15%', conditions: { companys: [1008, 1002, 1016, 1015, 1025, 1018, 1029, 1013, 1026, 1028, 1010, 1027, 1014, 1024, 1009, 1031, 1020, 1003, 1004, 1011, 1023] } }, 
    { id: 100021, name: 'ชำระเต็มจำนวน (เงินสด ) ตัวแทนออฟฟิต บริษัท กลุ่ม 1,2,3', conditions: [] }, 
    { id: 100020, name: 'ชำระเต็มจำนวน ( บัตรเครดิต )', conditions: { companys: [1030, 1002, 1016, 1015, 1025, 1018, 1029, 1023, 1013, 1028, 1011, 1010, 1027, 1024, 1009, 1014, 1020, 1003, 1004, 1026, 1008] } }, 
    { id: 100013, name: 'ผ่อน 2 เดือน', conditions: { companys: [1008, 1002, 1016, 1015, 1025, 1018, 1029, 1013, 1026, 1028, 1010, 1027, 1014, 1024, 1009, 1031, 1020, 1003, 1004, 1011, 1023] } }, 
    { id: 100012, name: 'พ.ร.บ.', conditions: { companys: [1030, 1002, 1016, 1015, 1018, 1025, 1029, 1023, 1013, 1011, 1028, 1010, 1027, 1014, 1020, 1003, 1004, 1026, 1008] } }
]

export const META_SERVICEs = [
    { id: 1006, name: 'ค่าบริการต่อภาษี รถเก๋ง/กระบะ' }, 
    { id: 1005, name: 'ค่าบริการต่อภาษี รถ จยย.' }, 
    { id: 1009, name: 'ค่าภาษี' }, 
    { id: 1004, name: 'ตรวจสภาพ รถกระบะ' }, 
    { id: 1003, name: 'ตรวจสภาพ รถเก๋ง' }, 
    { id: 1008, name: 'ตรวจสภาพ รถ จยย.' }
]

export const ORDER_ATTACH_TAGs = [
    { id: 'checkup_note', name: 'บันทึกตรวจสภาพ' }, 
    { id: 'vehicle_f', name: 'ด้านหน้า' }, 
    { id: 'vehicle_b', name: 'ด้านหลัง' }, 
    { id: 'vehicle_l', name: 'ด้านซ้ายเต็มคัน' }, 
    { id: 'vehicle_r', name: 'ด้านขวาต็มคัน' }, 
    { id: 'vehicle_fr', name: 'มุมหน้าขวา' }, 
    { id: 'vehicle_fl', name: 'มุมหน้าซ้าย' }, 
    { id: 'vehicle_br', name: 'มุมหลังขวา' }, 
    { id: 'vehicle_bl', name: 'มุมหลังซ้าย' }, 
    { id: 'vehicle_bt', name: 'หลังคารถ' }, 
    { id: 'vehicle_mileage', name: 'เลขไมล์รถ' }, 
    { id: 'other', name: 'อื่นๆ' }
]

export const OVERDUE_FINEs = [
    { day: 1, money: 0 }, 
    { day: 2, money: 0 }, 
    { day: 3, money: 0 }
]

export const PAYCOND_TYPEs = [
    { id: 10, name: 'หักจากยอดชำระลูกค้า' }, 
    { id: 20, name: 'จ่ายแยกจากยอดชำระของลูกค้า' }
]

export const PAYLOT_COUNTs = [
    { id: 0, name: 'เงินสด', name_s: 'เงินสด', icon: 'wallet', color: 'red' }, 
    { id: 2, name: 'ผ่อน 2 เดือน', name_s: '2ด.', icon: 'money-bill-wave-alt', color: 'pink' }, 
    { id: 3, name: 'ผ่อน 3 เดือน', name_s: '3ด.', icon: 'money-bill-wave', color: 'purple' }, 
    { id: 4, name: 'ผ่อน 4 เดือน', name_s: '4ด.', icon: 'money-bill-alt', color: 'indigo' }, 
    { id: 5, name: 'ผ่อน 5 เดือน', name_s: '5ด.', icon: 'money-bill', color: 'blue' }, 
    { id: 6, name: 'ผ่อน 6 เดือน', name_s: '6ด.', icon: 'sack-dollar', color: 'green' }, 
    { id: 8, name: 'ผ่อน 8 เดือน', name_s: '8ด.', icon: 'box-usd', color: 'yellow' }, 
    { id: 10, name: 'ผ่อน 10 เดือน', name_s: '10ด.', icon: 'envelope-open-dollar', color: 'orange' }, 
    { id: 11, name: 'ผ่อน 11 เดือน', name_s: '11ด.', icon: 'envelope-open-dollar', color: 'orange' }, 
    { id: 12, name: 'ผ่อน 12 เดือน', name_s: '12ด.', icon: 'file-invoice-dollar', color: 'brown' }
]

export const PAYMENT_CHANNELs = [
    { id: 'เงินสด', name: 'เงินสด' }, 
    { id: 'โอนเงิน', name: 'โอนเงิน' }, 
    { id: 'เช็ค', name: 'เช็ค' }, 
    { id: 'บัตรเครดิต', name: 'บัตรเครดิต' }, 
    { id: 'ชำระผ่านบริษัทประกันภัย', name: 'ชำระผ่านบริษัทประกันภัย' }, 
    { id: 'pay.sn', name: 'pay.sn' }, 
    { id: 'K-CASH', name: 'K-CASH' }, 
    { id: 'สแกนจ่ายSCB', name: 'สแกนจ่ายSCB' }
]

export const PAYWAY_TYPEs = [
    { id: 10, name: 'เงินสด', payagent: 1, payemp: 1, payservice: 1 }, 
    { id: 20, name: 'เช็ค' }, 
    { id: 30, name: 'โอนเงิน', bank: 1, payemp: 1, payservice: 1 }, 
    { id: 35, name: 'โอนเงินสดย่อย', payagent: 1 }, 
    { id: 40, name: 'บัตรเครดิต' }, 
    { id: 50, name: 'หักจากยอดชำระของลูกค้า', payagent: 1 }
]

export const REVENUE_COND_OPTIONs = [
    { id: 'agent_must', name: 'ต้องมีตัวแทน' }, 
    { id: 'agent_no', name: 'ต้องไม่มีตัวแทน' }, 
    { id: 'interest_must', name: 'ต้องมีดอกเบี้ย' }, 
    { id: 'interest_no', name: 'ต้องไม่มีดอกเบี้ย' }, 
    { id: 'discount_no', name: 'ต้องไม่มีส่วนลด' }, 
    { id: 'pay_gt', name: 'ยอดชำระ >' }
]

export const REVENUE_MONEY_OPTIONs = [
    { id: 'money_insure', name: 'เบี้ยสุทธิ' }, 
    { id: 'money_total', name: 'เบี้ยรวม' }, 
    { id: 'money_net', name: 'ยอดเงินรวม' }
]

export const SHOP_TYPEs = [
    { id: 10, name: 'ห้างหุ้นส่วนจำกัด', short: 'หจก.', prefix: 'ห้างหุ้นส่วน', suffix: 'จำกัด' }, 
    { id: 20, name: 'บริษัทจำกัด', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด' }, 
    { id: 30, name: 'บริษัทมหาชนจำกัด', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด' }, 
    { id: 40, name: 'ห้างหุ้นส่วนสามัญ', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด' }, 
    { id: 50, name: 'ร้านค้า', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด' }, 
    { id: 60, name: 'บุคคลธรรมดา', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด' }, 
    { id: 70, name: 'คณะบุคคล', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด' }, 
    { id: 80, name: 'มูลนิธิ', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด' }, 
    { id: 90, name: 'สมาคม', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด' }, 
    { id: 100, name: 'กิจการร่วมค้า', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด' }, 
    { id: 900, name: 'อื่นๆ', short: 'บจก.', prefix: 'บริษัท', suffix: 'จำกัด' }
]

export const VEHICLE_ADDITION_OPTIONs = [
    'อุปกรณ์ดัมพ์', 
    'เครื่องไฮโดรลิก', 
    'เครื่องทำความเย็น'
]

export const VEHICLE_PLACEFIXs = [
    { id: 0, name: 'ซ่อมอู่' }, 
    { id: 1, name: 'ซ่อมศูนย์' }
]

export const VEHICLE_USAGEs = [
    'ส่วนบุคคล', 
    'ใช้เพื่อการพาณิชย์', 
    'ใช้เพื่อการพาณิชย์พิเศษ', 
    'รับจ้างสาธารณะ', 
    'อื่นๆ'
]

export const WEB_CONTENT_TYPEs = [
    { id: 0, name: 'ข่าวสาร' }, 
    { id: 1, name: 'รีวิว' }, 
    { id: 2, name: 'คำถามที่พบบ่อย' }, 
    { id: 3, name: 'บทความ' }, 
    { id: 4, name: 'privacy' }, 
    { id: 5, name: 'cookie' }
]

export const YIELDTYPEs = [
    { id: 11, name: 'ผลตอบแทน' }, 
    { id: 12, name: 'รายการหัก' }
]

export const YIELDs = [
    { id: 11, name: 'ผลตอบแทน', items: [        { id: 1001, name: 'ค่าคอมฯ', taxHold: 3, taxVat: 7 },         { id: 1004, name: 'ค่าคอมฯ OV', taxHold: 3, taxVat: 7 },         { id: 1014, name: 'ค่าคอมฯพิเศษ', taxHold: 3, taxVat: 7 },         { id: 1024, name: 'ค่าคอมคีย์งาน', taxHold: 3, taxVat: 7 }
    ] }
]
