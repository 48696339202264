import React, { useMemo } from 'react'

import { FormContainer, Input, InputDate, Select } from 'unno-comutils/form'
import { get, num } from 'unno-comutils'
import { calMoneyDFI, calMoneyInsure, calMoneyNet, calPaylotDate } from '../../../service/calculator'
import { DEFAULT_DOCUMENT_PAYLOT } from '../../../service/var.default'
import { Icon } from 'unno-comutils/ui'
import { META_PAYLOTs } from '../../../service/var.meta'

export function FormPay (props: any) {
    const { document, onChange } = props

    const loadPaylot = (id: any) => {
        get('setting/meta/paylot/get/' + id).then(({ ok, data }) => {
            if (ok) {
                const update = {
                    ...document,
                    __paylotCount: data.count,
                    __payMeta: {
                        id: data.id,
                        name: data.name,
                        rate: data.rate,
                        dues: data.dues,
                    },
                }
                if (!update.paylots) update.paylots = []
                const c = update.paylots.length
                if (update.__paylotCount > c) {
                    const add = update.__paylotCount - c
                    for (let i = 0; i < add; i++)
                        update.paylots.push(DEFAULT_DOCUMENT_PAYLOT())
                }

                else if (update.__paylotCount < c) {
                    update.paylots = update.paylots.slice(0, update.__paylotCount)
                }

                calMoneyInsure(update)
                calPaylotDate(update, update.date)
                calMoneyDFI(update)
                onChange(update)
            }
        })
    }

    const optPAYLOTs = useMemo(() => {
        if (document && document.company)
            return META_PAYLOTs.filter((pay: any) => pay.conditions.companys && pay.conditions.companys.indexOf(document.company.id) >= 0)
        return []
    }, [document?.company])

    if (!document) return <></>

    return <FormContainer collapse={'book_pay'} label={'การชำระเงิน'} className={'docform-pay'}
                          header={<Icon button name={'sync'} onClick={() => loadPaylot(document.__payMeta?.id || 0)}/>}>
        {!props.create
            ? <Input readOnly label="ประเภทการชำระ" value={document.__payMeta?.name}/>
            : <Select label="ประเภทการชำระ" className="w-fill" options={optPAYLOTs} value={document.__payMeta?.id || ''} onChange={v => loadPaylot(v)}/>}
        <div className="row">
            <Input key={'mt_' + document.moneyInsure} label="เบี้ยประกันภัยสุทธิ" classBox="col w-2/12 un-input-text-mb" unit="บาท" right
                   defValue={document.moneyInsure} onBlur={v => onChange(calMoneyInsure({ ...document, moneyInsure: v }))}/>
            <Input readOnly label="อากร (0.4%)" classBox="col w-2/12" unit="บาท" right value={num(document.moneyStamptax, 2)}/>
            <Input readOnly label="ภาษี (7%)" classBox="col w-2/12" unit="บาท" right value={num(document.moneyTax, 2)}/>

            <Input key={'imiscount_' + document.moneyDiscount} label={`ส่วนลดลูกค้า (${document?.__payMeta?.rate?.discount || '-'})`}
                   classBox="col w-2/12" unit="บาท" right type="number" defValue={document.moneyDiscount} onBlur={v => onChange(calMoneyNet({ ...document, moneyDiscount: v }))}/>
            <Input key={'imfee_' + document.moneyFee} label={`ค่าธรรมเนียม (${document?.__payMeta?.rate?.fee || '-'})`}
                   classBox="col w-2/12" unit="บาท" right type="number" defValue={document.moneyFee} onBlur={v => onChange(calMoneyNet({ ...document, moneyFee: v }))}/>
            <Input key={'imnterest_' + document.moneyInterest} label={`ดอกเบี้ย (${document?.__payMeta?.rate?.interest || '-'})`}
                   classBox="col w-2/12" unit="บาท" right type="number" defValue={document.moneyInterest} onBlur={v => onChange(calMoneyNet({ ...document, moneyInterest: v }))}/>
        </div>

        <div className="row _input-last">
            {props.order && <InputDate fixWidth icon label="วันนัดจ่าย" classBox="col w-2/12" value={document.dateOrderDue} onChange={v => onChange({ dateOrderDue: v })}/>}
            <Input key={'imfine_' + document.moneyFine} type="number" label="ค่าปรับ" classBox="col w-2/12 ml-auto" unit="บาท" right defValue={document.moneyFine}
                   onBlur={v => onChange({ moneyFine: v })}/>
            <Input readOnly label="เบี้ยประกันภัยรวม" classBox="col w-2/12" unit="บาท" right value={num(document.moneyTotal, 2)}/>
            <Input readOnly label="ยอดเงินรวม" classBox="col w-2/12" unit="บาท" right value={num(document.moneyNet, 2)}/>
        </div>

        <Input key={'inote_' + document.note} label="หมายเหตุ" multiline={3} defValue={document.note} onBlur={v => onChange({ note: v })}/>
    </FormContainer>
}
