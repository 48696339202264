import React, { useEffect, useMemo, useState } from 'react'

import { clsNames, date, debounce, get, isEmpty } from 'unno-comutils'
import { Checkbox, FormContainer, Input, InputDate, Radio, Select } from 'unno-comutils/form'
import { visBlock } from '../wait'
import { isAct } from '../data'
import { ADDRESS_PROVINCEs, CAR_BRANDs, VEHICLE_USAGEs } from '../../../service/var.meta'

export const DEFAUTL_VEHICLE_DATA = () => ({ brand: '', generation: '', year: '', subGeneration: '' })

export function calVehicleTax (form: any) {
    let vehicle_type = form.type || 0
    let vehicle_cc = form.cc || ''
    let vehicle_weight = form.weight || ''
    let year_age = new Date().getFullYear() - (form.year || 0) + 1

    let money = 0
    if (vehicle_type === 1 || vehicle_type === 2) { // รถเก๋ง
        if (vehicle_cc > 1800)
            money = 2100 + (vehicle_cc - 1800) * 4
        else if (vehicle_cc > 600)
            money = 300 + (vehicle_cc - 600) * 1.5
        else if (vehicle_cc > 1)
            money = vehicle_cc * 0.5

        if (year_age >= 10)
            money -= money * 0.5
        else if (year_age >= 9)
            money -= money * 0.4
        else if (year_age >= 8)
            money -= money * 0.3
        else if (year_age >= 7)
            money -= money * 0.2
        else if (year_age >= 6)
            money -= money * 0.1
    } // รถเก๋ง, ไม่เกิน 7 ที่นั่ง 4 ประตู

    else if (vehicle_type === 3 && vehicle_weight > 0) { // รถบรรทุกส่วนบุคคล
        if (vehicle_weight > 2500)
            money = 1950
        else if (vehicle_weight > 2000)
            money = 1650
        else if (vehicle_weight > 1750)
            money = 1350
        else if (vehicle_weight > 1500)
            money = 1050
        else if (vehicle_weight > 1250)
            money = 900
        else if (vehicle_weight > 1000)
            money = 750
        else if (vehicle_weight > 750)
            money = 600
        else if (vehicle_weight > 500)
            money = 450
        else if (vehicle_weight <= 500)
            money = 300
    } //

    else if (vehicle_type === 4 && vehicle_weight > 0) { // รถตู้
        if (vehicle_weight > 7000)
            money = 3600
        else if (vehicle_weight > 6000)
            money = 3400
        else if (vehicle_weight > 5000)
            money = 3200
        else if (vehicle_weight > 4500)
            money = 3000
        else if (vehicle_weight > 4000)
            money = 2800
        else if (vehicle_weight > 3500)
            money = 2600
        else if (vehicle_weight > 3000)
            money = 2400
        else if (vehicle_weight > 2500)
            money = 2200
        else if (vehicle_weight > 2000)
            money = 1900
        else if (vehicle_weight > 1750)
            money = 1600
        else if (vehicle_weight > 1500)
            money = 1300
        else if (vehicle_weight > 1250)
            money = 1000
        else if (vehicle_weight > 1000)
            money = 800
        else if (vehicle_weight > 750)
            money = 450
        else if (vehicle_weight > 500)
            money = 300
        else if (vehicle_weight <= 500)
            money = 150
    } //

    return money
}

export function FormVehicle (props: any) {
    const { document, onChange } = props

    // ----- ACTION

    const checkVehicleNumber = debounce((number) => {
        if (isEmpty(number)) {
            onChange({ isNew: true })
        }
        else {
            get('book/check', { id: document.id, vehicleNumber: number, metaInsuretype: document.metaInsuretype?.id || document.metaInsuretype || 0 }).then(async d => {
                if (d.ok) {
                    onChange({ isNew: d.isNew })
                }
                else {
                    onChange({ isNew: true })
                }
            })
        }
    }, 3000)

    // ----- EVENT

    const onVehicleChangeTax = (update: any) => {
        onChange({
            ...update,
            vehicleTax: update.vehicleTax && update.metaVehicle
                ? calVehicleTax({ type: 1, cc: update.metaVehicle.cc, weight: '', year: update.metaVehicle.year })
                : 0
        })
    }

    const onVehicleNumber = (number: any) => {
        onChange({ vehicleNumber: number })
        checkVehicleNumber(number)
    }

    // ----- MEMO

    const visibleBlock = useMemo(() => !props.wait || visBlock(document), [props.wait, document])

    const { isVehicleUsage_other } = useMemo(() => {
        if (!document) return {}
        const isVehicleUsage_other = !!document?.vehicleUsage && !VEHICLE_USAGEs.some((v: any) => document.vehicleUsage === v)
        return { isVehicleUsage_other }
    }, [document])

    useEffect(() => {
        checkVehicleNumber(document?.vehicleNumber || '')
    }, [document?.metaInsuretype])

    // ----- RENDER

    if (!document) return <></>

    return <FormContainer collapse={'book_vehicle'} label={'ข้อมูลรถ'} className={'docform-vehicle'}
                          headerCollapse={<div className="un-form-header-text">
                              <span className="mr-2">{document.vehicleLicense} {document.vehicleProvice?.name}</span>
                          </div>}>
        <div className="row">
            {visibleBlock && <InputDate icon classBox="col w-1/6" label="วันจดทะเบียน" value={document.vehicleDate} onChange={v => onChange({ vehicleDate: v })}/>}
            <Input label="ทะเบียนรถ" classBox="col w-1/6" center value={document.vehicleLicense} onChange={v => onChange({ vehicleLicense: v })}/>
            <Select label={'จังหวัด'} classBox="col w-1/4" options={ADDRESS_PROVINCEs} value={document.vehicleProvice} onChange={v => onChange({ vehicleProvice: v })}/>
        </div>

        <InputVehicle value={document.metaVehicle} vehicleData={document.vehicleData} onChange={(_, v) => onVehicleChangeTax({ vehicleTax: document.vehicleTax, metaVehicle: v })}
                      onChangeVehicle={(v: any) => onChange({ vehicleData: v })}/>

        <div className="flex _vehicle_usages">
            <Radio label="ประเภทการใช้รถ" options={[...VEHICLE_USAGEs.map((v: any) => ({ id: v === 'อื่นๆ' ? '-' : v, name: v }))]}
                   value={isVehicleUsage_other ? '-' : document.vehicleUsage} onChange={v => onChange({ vehicleUsage: v })}/>
            {isVehicleUsage_other &&
                <Input label="โปรดระบุ" className="ml-4 _vehicle-mt w-fill"
                       value={document.vehicleUsage === '-' ? '' : document.vehicleUsage} onChange={v => onChange({ vehicleUsage: v })}/>}
        </div>

        {visibleBlock && <div className="row">
            <Input label="เลขตัวถัง" classBox="col w-1/2 _input-mb-6" value={document.vehicleNumber} onChange={onVehicleNumber}/>
            <Input label="เลขเครื่องยนต์" classBox="col w-1/2" value={document.vehicleNumberEngine} onChange={v => onChange({ vehicleNumberEngine: v })}/>
        </div>}

        {visibleBlock && <div className="row">
            <div className="col w-1/2 flex-on">
                <Checkbox text={'ภาษี'} checked={document.vehicleTax > 0} onChange={v => onVehicleChangeTax({ vehicleTax: v ? 1 : 0, metaVehicle: document.metaVehicle })}/>
                {document.vehicleTax > 0 &&
                    <Input right className={'ml-1 w-32'} type={'number'} apppend={'บาท'} value={document.vehicleTax} onChange={v => onChange({ vehicleTax: v })}/>}
            </div>

            {!isAct(document) && <Select classBox="col w-1/2" label={'พรบ.'} url={`ac/document_act/${document.id}?license=${document.vehicleLicense}`}
                                         renderText={(d: any) => `(${date(d.date, 'S')}) ${d.metaInsuretype?.name} , ${d.vehicleLicense} , ${d.insuranceNumber}`}
                                         value={document.documentAct} onChange={(_, v) => onChange({ documentAct: v })}/>}

        </div>}

        {visibleBlock && <div className={'row'}>
            <InputDate label="วันที่นัดตรวจรถ" classBox="col w-1/6 _input-mb-6" value={document.vehicleCheckDate} onChange={(v) => onChange({ vehicleCheckDate: v })}/>
            <Input label="สถานที่ตรวจรถ" classBox="col w-fill" value={document.vehicleCheckNote} onChange={v => onChange({ vehicleCheckNote: v })}/>
        </div>}
    </FormContainer>
}

const DEFAULT_VEHICLE = () => ({ generation: undefined, subGeneration: undefined, year: undefined })

export function InputVehicle (props: { value: any, vehicleData?: any, onChange: (v: number, data: any) => void, onChangeVehicle?: any, className?: string, noAdd?: boolean }) {
    const { vehicleData } = props

    const [brand, setBrand] = useState(vehicleData?.brand || '')

    const [select, setSelect] = useState(DEFAULT_VEHICLE())
    const [lists, setLists] = useState<any[]>([])

    const loadBrand = () => {
        if (brand) {
            get('car/in_brand', { brand }).then((d: any) => {
                if (d.ok) {
                    setLists(d.datas.map((d: any) => ({ ...d, generation: d.generation.toUpperCase(), subGeneration: d.subGeneration, cc: d.cc, year: d.year })))
                }
            })
        }
        else {
            setLists([])
            setSelect(DEFAULT_VEHICLE())
        }
    }

    const onChangeBrand = (brand: any) => {
        setSelect(DEFAULT_VEHICLE())
        setBrand(brand)

        console.log('onChangeBrand', brand)
        props.onChangeVehicle({ ...vehicleData, brand: brand || '' })
        if (!brand) props.onChange(0, null)
    }

    const onVehicleSelect = (update: any) => {
        const u = { ...select, ...update }
        setSelect(u)
        if (!u.generation || !u.subGeneration || !u.year) {
            props.onChange(0, null)
        }
    }

    const onUpdateVehicle = debounce(() => {
        props.onChangeVehicle({ ...vehicleData, generation: select.generation || '', year: select.year || '', subGeneration: select.subGeneration || '' })
    }, 300)

    useEffect(() => {
        const { brand, generation, year, subGeneration } = vehicleData || {}
        setBrand(brand || '')
        setSelect({ generation: generation || undefined, year: year || undefined, subGeneration: subGeneration || undefined })
    }, [])

    /*useEffect(() => {
        if (props.value && typeof props.value === 'object') {
            if (props.value.brand !== brand) setBrand(props.value.brand)
            if (props.value.generation && props.value.year && props.value.subGeneration) {
                if ((props.value.generation.toUpperCase() !== select.generation || props.value.year != select.year || props.value.subGeneration.toUpperCase() !== select.subGeneration))
                    setSelect({ generation: props.value.generation.toUpperCase(), year: props.value.year, subGeneration: props.value.subGeneration.toUpperCase() })
            }
        }
    }, [props.value])*/

    useEffect(() => {
        loadBrand()
    }, [brand])

    useEffect(() => {
        if (lists && select.generation !== undefined && select.subGeneration !== undefined && select.year !== undefined) {
            const o = lists.find((d: any) => d.generation === select.generation && d.subGeneration === select.subGeneration && d.year == select.year)
            if (o) {
                if (props.value?.id !== o?.id)
                    props.onChange(o.id, o)
            }
        }
        onUpdateVehicle()
    }, [lists, select])

    const brands = useMemo(() => CAR_BRANDs.map(d => d.name), [])

    const { gens, subGenerations, years } = useMemo(() => {
        const gens: any[] = lists?.reduce((s: string[], d: any) => {
            if (s.indexOf(d.generation) < 0) s.push(d.generation)
            return s
        }, []).filter(Boolean) || []

        if (brand && select.generation && !gens.some((s: any) => s === select.generation))
            gens.unshift(select.generation)

        const years: any[] = lists?.reduce((s: string[], d: any) => {
            if (d.generation === select.generation && s.indexOf(d.year) < 0) s.push(d.year)
            return s
        }, []).filter(Boolean) || []
        years.sort()

        if (select.year && !years.some((s: any) => s === select.year))
            years.unshift(select.year)

        const subGenerations: any[] = lists?.reduce((s: string[], d: any) => {
            if (d.generation === select.generation && d.year == select.year && s.indexOf(d.subGeneration) < 0) s.push(d.subGeneration)
            return s
        }, []).filter(Boolean) || []

        if (select.subGeneration && !subGenerations.some((s: any) => s === select.subGeneration))
            subGenerations.unshift(select.subGeneration)

        let update: any = {}
        if (gens.length === 1 && select.generation === undefined) update = { generation: gens[0], year: undefined, subGeneration: undefined }
        if (years.length === 1 && select.year === undefined) update = { year: years[0], subGeneration: undefined }
        if (subGenerations.length === 1 && select.subGeneration === undefined) update = { subGeneration: subGenerations[0] }

        if (Object.keys(update).length > 0)
            setSelect(prev => ({ ...prev, ...update }))

        return { gens, subGenerations, years }
    }, [lists, select])

    return <div className={clsNames('book-vehicle-selector', 'un-boxmargin', props.className)}>
        <Select m0 label={'ยี่ห้อ'} options={brands} value={brand} onChange={onChangeBrand}/>
        <Select m0 label={'รุ่น'} options={gens || []} value={select.generation}
                onChange={(d: any) => onVehicleSelect({ generation: d, subGeneration: undefined, year: undefined })}/>
        <Select m0 label={'ปี'} className={'-year'} options={years || []} value={select.year} onChange={(d: any) => onVehicleSelect({ year: d, subGeneration: undefined })}/>
        <Select m0 label={'รุ่นย่อย'} className={'-last'} options={subGenerations || []} value={select.subGeneration}
                onChange={(d: any) => onVehicleSelect({ subGeneration: d })}/>
    </div>
}