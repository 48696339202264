import React, { useEffect, useState } from 'react'

import { clsNames, date, dbdate, dConfirm, get, isEmpty, num, post, tSuccess } from 'unno-comutils'
import { Button, Icon, List, ListBody, ListButton, ListContainer, ListHead, Modal, PageScroll } from 'unno-comutils/ui'
import { Checkbox, FormContainer, Input, InputDate, Photo, Radio, Select, Upload } from 'unno-comutils/form'

import { AlertPing, Markdown } from './common'
import { IconDelete } from 'unno-comutils/Icon'
import { CustomerFormPay, CustomerPaySuccess, DEFAULT_PAY_SUCCESS } from '../pages/account/customer'
//import Photo from './test/Photo'
import { InputVehicle } from '../pages/book/components/vehicle'

export function Room (props: { current: any, room: any, info?: { roomSelected: number[] }, onClick: (id: number, e: any) => void }) {
    const { room, current, onClick, info } = props
    return <div className={clsNames('chat-room', current && current.id === room.id && '-active', info && info.roomSelected?.indexOf(room.id) >= 0 && '-selected')}
                onClick={(e: any) => onClick(room.id, e)}>
        {room.avatar ? <div className={'_profile'} style={{ backgroundImage: 'url(' + room.avatar + ')' }}/> : <Icon name={'user-circle'} className={'_avatar'}/>}
        <div className="_detail">
            <div className={'_title'}>
                {room.isHide && <div className="_hide">ซ่อน</div>}
                <div className={clsNames('_name', !!room.customer && '-customer')}>{room.customer || room.title}</div>
                {room.alert && <AlertPing className="_alert"/>}
                <div className={'_time'}>{date(room.updateTime, 'St')}</div>
            </div>
            <div className="_last">{room.messageLast.substring(0, 60)}</div>
            {room.messageOffice && <div className="_last-office">{room.messageOffice.substring(0, 60)}</div>}
            <div className="_sub">
                {!!room.state && !!room.customer && <span className="_state">{room.state}</span>}
                {!!room.user && <span className="_emp">{room.user}</span>}
                {!!room.customer && <Icon name={'user-circle'} className="_customer"/>}
            </div>
        </div>
        {room.provider && <div className={clsNames('chat-room-provider', '-' + room.provider)}>{room.provider}</div>}
    </div>
}

export function RoomSummary (props: { onSelectAll: (check: boolean) => void }) {
    const [roomSelectAll, setRoomSelectAll] = useState(false)

    const onSelectAll = () => {
        setRoomSelectAll(!roomSelectAll)
        props.onSelectAll(!roomSelectAll)
    }

    return <div className="_summary">
        <Icon name={clsNames('check-square', '_check-all', roomSelectAll && '-check')} className={'mr-2'} onClick={onSelectAll}/>
    </div>
}

export function Message (props: any) {
    const d = props.data
    if (!d) return null

    const deleteMessage = (data: any) => {
        dConfirm('ยืนยันการลบข้อความนี้').then(() => {
            post('chat/delete_message', { id: data._id, index: data._index }).then((d: any) => {
                if (d.ok) {
                    tSuccess('ลบข้อมูลเรียบร้อยแล้ว')
                    if (props.onChange) props.onChange({ deleteUser: d.user })
                }
            })
        })
    }

    if (d.type && d.type === 'spliter') return <div key={'spt_' + d.text} className={'chat-message-spliter'}>{d.text}</div>

    if (d.mode && d.mode === 'notify') {
        let text
        if (d.text.startsWith('{') && d.text.endsWith('}')) {
            try {
                const notify = JSON.parse(d.text)
                if (notify.altText) {
                    text = <a href={notify.baseUrl} target={'_blank'} className={'_link'} rel="noreferrer">{notify.altText}</a>
                }
                else if (notify.text) {
                    text = <span className={'_text'}>{notify.text}</span>
                }
                else if (notify.type) {
                    if (notify.type === 'image' && notify.previewImageUrl && notify.originalContentUrl) {
                        text = <a href={notify.originalContentUrl} target="_blank" className={'_image'}><img src={notify.previewImageUrl} alt={'ALT'}/></a>
                    }
                }
            } catch (e) {}
        }
        else {
            text = <span className={'_text'}>{d.text}</span>
        }

        if (!!text) {
            return <div key={'notify_' + d._id} className={'chat-message-notify'}>
                <span className={'_time'}>{date(d.time, 'St')}</span>
                {!!d.user && !!d.user.name && <span className={'_user'}>{d.user.name}</span>}
                {text}
            </div>
        }
    }

    if (d.mode && d.mode === 'boardcast') {
        return <div key={'boardcast_' + d._id} className={'chat-message-boardcast'}>
            <div className="_head">
                <Icon name={'bullhorn'}/>
                <span className={'_time'}>{date(d.time, 'St')}</span>
                {!!d.user && !!d.user.name && <span className={'_user'}>{d.user.name}</span>}
            </div>
            <div className="_content">{d.text}</div>
        </div>
    }

    if (d.mode && d.mode === 'action') {
        try {
            const { type, ...data } = JSON.parse(d.data) as any
            return <div key={'notify_' + d._id} className={'chat-message-item'}>
                <div className={'_on'}>
                    <div className={'_date'}>{date(d.time, 'S')}</div>
                    <div className={'_time'}>{date(d.time, 't')}</div>
                    {!!d.user && !!d.user.name && <div className={'_user'}>{d.user.name}</div>}
                </div>
                <div className="_content">
                    <MessageAction action={type} data={data}/>
                </div>
            </div>
        } catch {
            return null
        }
    }

    return <div key={'room_' + d._id} id={d._id} className={clsNames('chat-message-item', d.me && '-me', d.mode && '-' + d.mode)}>
        {d.mode === 'office' && <Icon button name={'times-circle red icon-delete'} onClick={() => deleteMessage(d)}/>}
        <div className={'_on'}>
            <div className={'_date'}>{date(d.time, 'S')}</div>
            <div className={'_time'}>{date(d.time, 't')}</div>
            {!!d.user && !!d.user.name && <div className={'_user'}>{d.user.name}</div>}
        </div>
        {!!d.deleteUser
            ? <div className="_content -delete">
                <div className={'text-content'}>ข้อความลบทิ้งแล้ว โดย {d.deleteUser.name}</div>
            </div>
            : <div className="_content">
                {!!d.text && <Markdown>{d.text}</Markdown>}
                {!!d.sticker && <Sticker data={d.sticker}/>}
                {d.files?.length > 0 && <div className="_files">{d.files
                    .filter((d: any) => !isEmpty(d.url))
                    .map((file: any, x: number) => <MessageFile key={'file_' + x} file={file}/>)}</div>}
                {!!d.readed && <div className={'_readed'}>อ่านแล้ว {date(props.room.messageReaded, 't')}</div>}
            </div>}
    </div>
}

export function MessageFile (props: any) {
    const { file } = props
    if (file.type === 'image')
        return <a href={file.url} target="_blank" rel="noreferrer" className={file.type === 'image' ? '_image' : '_file'}
                  style={file.type === 'image' ? { backgroundImage: 'url("' + file.url + '")' } : {}}/>
    if (file.type === 'video')
        return <a href={file.url} target="_blank" rel="noreferrer" className={'_file'}><Icon name={'video red'}/> {file.url.split('/').reverse()[0]}</a>
    return <a href={file.url} target="_blank" rel="noreferrer" className={'_file'}><Icon name={'file-alt blue'}/> {file.url.split('/').reverse()[0]}</a>
}

export function MessageWait (props: any) {
    if (props.data) {
        const { type, data } = props.data

        return <div key={'wait_' + type} className={'chat-message-item -me -wait'}>
            <div className={'_on'}><Icon name={'spinner'} spin/></div>
            <div className="_content">
                {type === 'text' && <Markdown>{data}</Markdown>}
                {type === 'sticker' && <Sticker data={data}/>}
            </div>
        </div>
    }
    return null
}

export function Sticker (props: { data: any }) {
    const { data } = props
    return <div className={'_sticker'}><img alt={'sticker'} src={'https://stickershop.line-scdn.net/stickershop/v1/sticker/' + data.sticker + '/android/sticker.png'}/></div>
}

//////////////////////////////////////////////////////////////

export function UserOnlineView (props: any) {
    const { userOnlines, open } = props

    const [wait, setWait] = useState<any>(true)
    const [lists, setLists] = useState<any>(null)

    // ----- ACTION

    const loadList = () => get('/chat/user_queue_log').then(d => d.ok && setLists(d.datas)).finally(() => setWait(false))

    // ----- RENDER

    return <Modal title={'เจ้าหน้าที่ออนไลน์'} open={open} onClose={props.onClose} onOpenEnd={loadList}>
        <ListContainer>
            <ListHead>
                <div className={'w-rn c'}>#</div>
                <div className={'w-fill'}>ชื่อ</div>
                <div className={'w-datetime r'}>คิวเวลา</div>
            </ListHead>
            <ListBody>
                {userOnlines.map((d: any, x: number) => <List key={'u' + d.id}>
                    <div className={'w-rn c'}>{x + 1}</div>
                    <div className={'w-fill'}>{d.name}</div>
                    <div className={'w-datetime r grey-blue'}>{date(d.time, 'St')}</div>
                </List>)}
            </ListBody>
        </ListContainer>
        <ListContainer className={'mt-3'} wait={wait}>
            <ListHead>
                <div className={'w-32 r'}>เวลา</div>
                <div className={'w-20 c'}>room</div>
                <div className={'w-20'}>action</div>
                <div className={'w-24 c'}>user</div>
                <div className={'w-fill'}>คิว</div>
            </ListHead>
            <ListBody>
                {lists && lists.map((d: any) => {
                    const queues = !d.queues ? '-' : d.queues.map((q: any) => q.name).join(' , ')
                    return <List key={'u' + d.id}>
                        <div className={'w-32 grey-blue r'}>{date(d.time, 'St')}</div>
                        <div className={'w-20 c purple'}>{d.room}</div>
                        <div
                            className={clsNames('w-20', d.action.includes('queue') && 'blue', d.action.includes('queue_out') && 'red', d.action.includes('queue_in') && 'green')}>{d.action}</div>
                        <div className={'w-24 c'}>{d.user?.name}</div>
                        <div className={'w-fill grey'}>{queues}</div>
                    </List>
                })}
            </ListBody>
        </ListContainer>
    </Modal>
}

export function ChatLogHistory (props: any) {
    const { room } = props

    const [logs, setLogs] = useState([])

    // ----- ACTION

    const loadLogs = () => {
        if (room) {
            get('chat/log/' + room.id).then((d: any) => {
                if (d.ok) {
                    setLogs(d.logs || [])
                }
            })
        }
    }

    // ----- RENDER

    return <Modal icon={'history'} title={room?.customer?.name || ''} open={room !== null} md onClose={props.onClose} onOpenEnd={loadLogs}>
        <ListContainer height={500}>
            <ListHead>
                <div className={'w-rn c'}>#</div>
                <div className={'w-fill'}>event</div>
                <div className={'w-40'}>update</div>
            </ListHead>
            <ListBody scroll>
                {logs.map((d: any, index: any) => <List key={'list_' + index}>
                    <div className={'w-rn c grey-blue'}>{index + 1}</div>
                    <div className={'w-fill'}>{d.eventType}</div>
                    <div className={'w-40'}>{d.eventTime}</div>
                </List>)}
            </ListBody>
        </ListContainer>
    </Modal>
}

//////////////////////////////////////////////////////////////

export function FieldForm (props: any) {
    const _current = () => !isEmpty(props.data) && Array.isArray(props.data) ? [...props.data] : []

    const saveData = (id: any, value: any) => {
        const outputs = _current()
        if (outputs.some((d: any) => d.id === id)) {
            const x = outputs.findIndex((d: any) => d.id === id)
            if (x >= 0) outputs[x].value = value
        }
        else {
            outputs.push({ id, value })
        }
        props.onChange(outputs)
    }

    const deleteDataGroup = (id: number, index: number) => {
        dConfirm('ยืนยันการลบข้อมูลนี้').then(() => {
            const outputs = _current()
            if (outputs.some((d: any) => d.id === id && d.type === 'group')) {
                const x = outputs.findIndex((d: any) => d.id === id && d.type === 'group')
                outputs[x].datas = outputs[x].datas.filter((_: any, x: number) => x !== index)
                props.onChange(outputs)
            }
        })
    }

    return <>
        {props.fields.map((group: any) => {
            if (group.isList) {
                const data = props.data && props.data.find((d: any) => d.id === group.id && d.type === 'group')
                const primaryFields = group.fields.some((f: any) => f.isPrimary) ? group.fields.filter((f: any) => f.isPrimary) : [group.fields[0]]
                return <FormContainer key={'g' + group.id} collapse={'field_' + group.id} vertical label={group.title}>
                    <ListContainer>
                        <ListHead>
                            <div className={'w-rn c'}>#</div>
                            {primaryFields.map((f: any) => <div key={'f' + f.id} className={'w-fill'}>{f.name}</div>)}
                            <div className={'w-icon'}/>
                        </ListHead>
                        <ListBody>
                            {data && data.datas && data.datas.map((d: any, x: any) => <List key={'g' + group.id}>
                                <ListButton fill onClick={() => props.onFormGroup({ id: data.id, datas: d.value, index: x, group })}>
                                    <div className={'w-rn c'}>{x + 1}</div>
                                    {primaryFields.map((f: any) => <div key={'f' + f.id} className={'w-fill'}>{d?.value?.find((e: any) => e.id === f.id)?.value || ''}</div>)}
                                </ListButton>
                                <IconDelete onClick={() => deleteDataGroup(data.id, x)}/>
                            </List>)}
                        </ListBody>
                    </ListContainer>
                    <Button icon={'plus'} sm success className={'mt-3'} m0 fullWidth onClick={() => props.onFormGroup({ group })}/>
                </FormContainer>
            }

            return <FormContainer key={'g' + group.id} collapse={'field_' + group.id} vertical label={group.title}>
                {group.fields?.map((f: any) => <FieldInput key={'f' + f.id} field={f} value={props.data?.find((d: any) => d.id === f.id)?.value || ''} saveData={saveData}/>)
                    .filter(Boolean)}
            </FormContainer>
        })}
    </>
}

export function FieldGroup (props: any) {
    const { group, index } = props.data || {}

    const [datas, setDatas] = useState<any>([])

    // ----- ACTION

    const _current = () => !isEmpty(props.fields) && Array.isArray(props.fields) ? [...props.fields] : []

    const saveDataGroup = (c: any) => {
        const outputs = _current()
        if (outputs.some((d: any) => d.id === group.id && d.type === 'group')) {
            const x = outputs.findIndex((d: any) => d.id === group.id && d.type === 'group')
            if (index !== undefined && outputs[x].datas?.length > index) {
                outputs[x].datas[index].value = datas
            }
            else {
                outputs[x].datas.push({ value: datas })
            }
        }
        else {
            outputs.push({ id: group.id, type: 'group', datas: [{ value: datas }] })
        }
        c()
        props.onChange(outputs)
        props.onClose()
    }

    // ----- EVENT

    const loadData = () => setDatas(!isEmpty(props.data?.datas) && Array.isArray(props.data?.datas) ? props.data?.datas : [])

    const onChange = (id: any, value: any) => {
        setDatas((prev: any) => {
            const outputs = !isEmpty(prev) && Array.isArray(prev) ? [...prev] : []
            if (outputs.some((d: any) => d.id === id)) {
                const x = outputs.findIndex((d: any) => d.id === id)
                if (x >= 0) outputs[x].value = value
            }
            else {
                outputs.push({ id, value })
            }
            return outputs
        })
    }

    // ----- MEMO

    useEffect(() => {
        loadData()
    }, [props.data])

    // ----- RENDER

    if (!props.data) return null
    return <Modal title={group.title} className={'chat-sidebar'} noBackdrop open={!!props.data} openEffect={'right'} startPosition={{ right: 0, top: 0 }} onClose={props.onClose}
                  footerSave={saveDataGroup}>
        <PageScroll container className={'pt-4'}>
            {group.fields?.map((f: any) => <FieldInput key={'f' + f.id} field={f} value={datas.find((d: any) => f.id === d.id)?.value || ''} saveData={onChange}/>).filter(Boolean)}
        </PageScroll>
    </Modal>
}

function FieldInput (props: any) {
    const { field, value, saveData } = props
    return <>
        {field && (() => {
            const _props = { key: 'i' + field.id + value, label: field.name, options: field.data, items: field.data }
            const type = field.type?.name

            if (type === 'text') return <Input {..._props} defValue={value} onBlur={v => saveData(field.id, v)}/>
            if (type === 'date') return <InputDate {..._props} value={value} onChange={v => saveData(field.id, dbdate(v))}/>
            if (type === 'select') return <Select {..._props} value={value} onChange={v => saveData(field.id, v)}/>
            if (type === 'checkbox') return <Checkbox {..._props} checked={value} onChange={v => saveData(field.id, v)}/>
            if (type === 'radio') return <Radio {..._props} value={value} onChange={(_, v) => saveData(field.id, v)}/>
            if (type === 'photo') return <Photo {..._props} multiple value={value} onChange={v => saveData(field.id, v)}/>
            if (type === 'file') return <Upload {..._props} multiple value={value} onChange={v => saveData(field.id, v)}/>
            if (type === 'vehicle') return <Vehicle {..._props} value={value} onChange={(v: any) => saveData(field.id, v)}/>
        })()}
    </>
}

function Vehicle (props: any) {
    const [form, setForm] = useState<any>(null)
    const [indexEdit, setIndexEdit] = useState<any>(null)

    const chatEdit = (value: any, index: any) => {
        setForm(value)
        setIndexEdit(index)
    }

    const chatSave = () => {
        if (form) {
            let _value = props.value && props.value.length > 0 ? [...props.value] : []
            if (indexEdit === null) {
                _value.push(form)
            }
            else {
                _value[indexEdit] = form
            }

            props.onChange(_value)
        }

        setIndexEdit(null)
        setForm({ brand: '' })
    }

    const chatDelete = (index: any) => props.onChange(props.value.filter((_: any, x: any) => x !== index))

    return <div className="chat-field chat-field-vehicle">
        {props.label && <div>{props.label}</div>}

        {!isEmpty(props.value) && Array.isArray(props.value) && <ListContainer className="mt-4">
            {props.value.map((d: any, x: any) => <List key={'vehicle-item_' + x} className="chat-field-vehicle-item">
                {d.year && <div className={'_year'}>{d.year}</div>}
                <ListButton fill onClick={() => chatEdit(d, x)}>
                    <div className="w-fill">
                        <div className="_gen">
                            <span>{d.brand || 'ไม่ระบุยี่ห้อ'}</span>
                            <span>{d.generation || 'ไม่ระบุรุ่น'}</span>
                        </div>
                        {d.subGeneration && <div className="_subgen">{d.subGeneration}</div>}
                    </div>
                </ListButton>
                <IconDelete onClick={() => chatDelete(x)}/>
            </List>)}
        </ListContainer>}

        <InputVehicle noAdd value={form} onChange={(id, v) => setForm(v)}/>

        {form && form.brand && <div className={'flex'}>
            <Button m0 sm success={indexEdit === null} warning={indexEdit >= 0} fullWidth onClick={chatSave}>
                {indexEdit === null ? 'เพิ่ม' : 'แก้ไขรายการที่ ' + (num(indexEdit) + 1)}
            </Button>
            {indexEdit !== null && <Button m0 sm className={'ml-2'} fullWidth onClick={() => {
                setIndexEdit(null)
                setForm({ brand: '' })
            }}>ยกเลิกแก้ไข</Button>}
        </div>}
    </div>
}

//////////////////////////////////////////////////////////////

export function MessageAction (props: { action: string, data: any }) {
    if (props.action === 'slip') return <MessageActionSlip id={props.data?.id}/>
    return <div className={'chat-message-helper'}>
        <div className="_header">?</div>
    </div>
}

function MessageActionWait () {
    return <div className="chat-message-helper">
        <div className="_header"><Icon name={'spinner mr-2'} spin/> กำลังโหลดข้อมูล...</div>
    </div>
}

function MessageActionError (props: { text?: string }) {
    return <div className="chat-message-helper">
        <div className="_header"><Icon name={'exclamation-triangle red mr-2'}/> {props.text || 'Error!!'}</div>
    </div>
}

function MessageActionSlip (props: any) {
    const [wait, setWait] = useState(true)
    const [data, setData] = useState<any>(null)

    const [formPay, setFormPay] = useState<any>(null)

    const loadData = () => {
        if (props.id) {
            get('customer_slip/get/' + props.id).then(({ ok, data }) => {
                if (ok) setData(data)
            }).finally(() => setWait(false))
        }
        else {
            setWait(false)
        }
    }

    const onSaved = () => {
        setFormPay(null)
        loadData()
    }

    useEffect(() => {
        loadData()
    }, [])

    if (wait) return <MessageActionWait/>
    if (!data) return <MessageActionError/>
    if (!data.paylot) return <MessageActionError text={'สลิปที่แจ้งมา ไม่ได้จับคู่'}/>

    const { paylot } = data
    return <div className="chat-message-helper">
        <div className="_header">สลิปที่แจ้งมา งวดที่ {paylot.lot}</div>
        <div className="_content flex">
            <a href={data.url} target={'_blank'} className="_photo" style={{ backgroundImage: `url("${data.url}")` }}/>
            <div className="_text">
                {paylot.isPay
                    ? <div>จ่ายแล้ว <span className="blue">{date(paylot.datePay)}</span> ยอด <span className="blue">{num(paylot.moneyPay, 0)}</span></div>
                    : <div>ยอดจ่าย <span className="red">{num(paylot.money, 0)}</span> กำหนดจ่ายวันที่ <span className="red">{date(paylot.dateDue)}</span></div>}
            </div>
        </div>
        <div className="_action">
            <Button sm m0 color={paylot.isPay ? 'green' : 'blue'} onClick={() => setFormPay({ id: paylot.id, slip: data.id })}>{paylot.isPay ? 'จ่ายแล้ว' : 'บันทึกจ่าย'}</Button>
        </div>
        {paylot.isPay
            ? <CustomerPaySuccess id={formPay?.id || null} onClose={() => setFormPay(DEFAULT_PAY_SUCCESS)}/>
            : <CustomerFormPay id={formPay?.id || null} slip={formPay?.slip} onSave={onSaved} onClose={() => setFormPay(null)}/>}
    </div>
}

//////////////////////////////////////////////////////////////

export function ProviderIcon (props: { name: string }) {
    if (props.name === 'fb') return <Icon name={'facebook blue'} brand/>
    if (props.name === 'ln') return <Icon name={'line green'} brand/>
    return <Icon name={'question grey'}/>
}