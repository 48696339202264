import React, { useEffect, useRef, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AppContext, clsNames, subscribe, tInfo, useAppStateContext } from 'unno-comutils'
import { checkLogin, logout } from 'unno-comutils/connect'
import Wait from 'unno-comutils/Wait'
import { User } from 'unno-comutils/utils'
import { ToastContainer } from 'unno-comutils/Dialog'
import 'unno-comutils/style/core.css'

import { addEvent, IsBETA, IsDEMO, sendEvent } from './utils'
import Routing from './service/router'
import Layout from './layout'
import './style/app.scss'

export default function App () {
    const [wait, setWait] = useState(0)
    const appState = useAppStateContext()

    const userRef = useRef(appState?.user)
    const notifyMuteRef = useRef(false)
    const beep = useRef(new Audio('https://files.un-no.net/static/sound/beep.mp3'))

    // ----- MEMO

    useEffect(() => {
        addEvent('notify_mute', (e: any) => {
            localStorage.setItem('OBI_NOTIFY_MUTE', e.detail.set ? '1' : '0')
            notifyMuteRef.current = e.detail.set
        })

        const ws = subscribe('obi', (d: any) => {
            if (d.action === 'notify' && userRef.current?.id > 0) {
                if (d.user === 0 || (userRef.current && d.user === userRef.current.id)) {
                    tInfo(d.text)
                    if (!notifyMuteRef.current) beep.current?.play()
                    sendEvent('notify_user')
                }
            }
        })
        return () => ws.close()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            checkLogin().then((d: any) => {
                if (d.ok) {
                    userRef.current = new User(d.user)
                    appState.setUser(userRef.current)
                    setWait(2)
                }
                else {
                    setWait(2)
                    appState.setUser(null)
                    logout()
                }
            })
        }, 200)

        document.body.className = clsNames('app-body', IsDEMO && ' -demo', IsBETA && ' -beta', localStorage.getItem('OBI_NO_FONT') !== '1' && '-use-font')

        if (localStorage.getItem('OBI_NOTIFY_MUTE') === '1')
            notifyMuteRef.current = true
    }, [])

    // ----- RENDER

    if (wait < 2) return <Wait color={wait ? 'blue' : 'pink'}/>
    return <AppContext.Provider value={appState}>
        <div className={'layout'}>
            <BrowserRouter>
                {!!appState.user ? <Layout children={<Routing/>}/> : <Routing/>}
            </BrowserRouter>
            <ToastContainer/>
        </div>
    </AppContext.Provider>
}
